<section class="feature-page">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <h1>Features</h1>
        <h3>The Best Email App for Mac and PC</h3>
        <h6>Powerful tools, intelligent views, and flexible workspaces. All presented within a
          clean, clear, and colorful interface.</h6>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <div class="feature-box" (click)="open(messagetriage)">
          <div class="feature-box-banner">
            <img src="/assets/images/features/message-triage.svg" class="img-fluid" alt="message-triage" />
          </div>
          <h4>Message Triage</h4>
          <p>From its medieval origins to the digital era, learn everything there is to know about the ubiquitous
            lorem ipsum.</p>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="feature-box" (click)="open1(emailorganization)">
          <div class="feature-box-banner">
            <img src="/assets/images/features/email-org.svg" class="img-fluid" alt="Email Organization" />
          </div>
          <h4>Email Organization</h4>
          <p>From its medieval origins to the digital era, learn everything there is to know about the ubiquitous
            lorem ipsum.</p>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="feature-box" (click)="open2(emailmanagement)">
          <div class="feature-box-banner">
            <img src="/assets/images/features/email-mngt.svg" class="img-fluid" alt="Email Management" />
          </div>
          <h4>Email Management</h4>
          <p>From its medieval origins to the digital era, learn everything there is to know about the ubiquitous
            lorem ipsum.</p>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="feature-box" (click)="open3(composition)">
          <div class="feature-box-banner">
            <img src="/assets/images/features/composition.svg" class="img-fluid" alt="Composition" />
          </div>
          <h4>Composition</h4>
          <p>From its medieval origins to the digital era, learn everything there is to know about the ubiquitous
            lorem ipsum.</p>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="feature-box" (click)="open4(search)">
          <div class="feature-box-banner">
            <img src="/assets/images/features/search.svg" class="img-fluid" alt="Search" />
          </div>
          <h4>Search</h4>
          <p>From its medieval origins to the digital era, learn everything there is to know about the ubiquitous
            lorem ipsum.</p>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="feature-box" (click)="open5(userinterface)">
          <div class="feature-box-banner">
            <img src="/assets/images/features/user-interface.svg" class="img-fluid" alt="User Interface" />
          </div>
          <h4>User Interface</h4>
          <p>From its medieval origins to the digital era, learn everything there is to know about the ubiquitous
            lorem ipsum.</p>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="feature-box" (click)="open6(safetysecurity)">
          <div class="feature-box-banner">
            <img src="/assets/images/features/safty-security.svg" class="img-fluid" alt="Safety & Security" />
          </div>
          <h4>Safety & Security</h4>
          <p>From its medieval origins to the digital era, learn everything there is to know about the ubiquitous
            lorem ipsum.</p>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="feature-box" (click)="open7(systemfeatures)">
          <div class="feature-box-banner">
            <img src="/assets/images/features/system-features.svg" class="img-fluid" alt="System Features" />
          </div>
          <h4>System Features</h4>
          <p>From its medieval origins to the digital era, learn everything there is to know about the ubiquitous
            lorem ipsum.</p>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="feature-box" (click)="open8(general)">
          <div class="feature-box-banner">
            <img src="/assets/images/features/general.svg" class="img-fluid" alt="General" />
          </div>
          <h4>General</h4>
          <p>From its medieval origins to the digital era, learn everything there is to know about the ubiquitous
            lorem ipsum.</p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Modal -->
<ng-template #messagetriage let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="message-triageLabel">Message Triage</h5>
    <button type="button" class="close btn p-0" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-1">
          <h4>Quick Bar</h4>
          <p>The Quick Bar is like macOS Spotlight, but for common mail actions. Type a few characters in
            the Quick Bar, then autocomplete against the suggestion. Supported actions include Quick Move,
            Quick Copy, Quick Post, new Tag/Topic, new Gmail Label, and Quick Folder Switch.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-2">
          <h4>Quick Move</h4>
          <p>File messages by typing the name of the target folder in the Quick Bar. Only the first few
            characters are needed, then auto-complete against suggestions offered.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-3">
          <h4>Quick Copy</h4>
          <p>Similar to Quick Move, Quick Copy will copy a message to the target folder entered in the
            Quick Bar. Type the first few characters of the target folder, then auto-complete against the
            suggestions to copy the message.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-4">
          <h4>Swipe Gestures on macOS</h4>
          <p>Postbox supports gestures for those using macOS with a trackpad or other device that supports
            multitouch gestures. There four gestures supported: Archive, Reminder, Junk, and Delete.</p>
          <p> Unlike other email apps, Postbox's swipe actions can be performed on multiple messages at
            once.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-1">
          <h4>Mailing List Unsubscribe</h4>
          <p>If Postbox detects a "List-Unsubscribe" attribute in a message header, it displays an
            Unsubscribe link next to the sender’s address, so you can unsubscribe with just one click.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box  fbox-bg-color-2">
          <h4>One-Click Archive</h4>
          <p>You can move messages from the Inbox to a dedicated Archive Folder by clicking the Archive
            button in the toolbar, or via the "a" keyboard shortcut.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-3 mb-0">
          <h4>Filters</h4>
          <p>Postbox provides the most complete filtering rule sets on the market – a power user's dream.
            Filters can be created per account or globally across all accounts, and can be run
            automatically, manually, or periodically. You can even add contacts to a filter on-the-fly
            without having to open the filter editor.</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- Modal -->
<ng-template #emailorganization let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="message-triageLabel">Email Organization</h5>
    <button type="button" class="close btn p-0" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-1">
          <h4>Account Groups</h4>
          <p>The Quick Bar is like macOS Spotlight, but for common mail actions. Type a few characters in
            the Quick Bar, then autocomplete against the suggestion. Supported actions include Quick Move,
            Quick Copy, Quick Post, new Tag/Topic, new Gmail Label, and Quick Folder Switch.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-2">
          <h4>Focus Pane</h4>
          <p>The Focus Pane lets you focus on what you need, and nothing else. The Focus Pane zeros in on messages by
            attribute (such as being unread or having an attachment), contact, topic, and date.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-3">
          <h4>Tagging</h4>
          <p>Postbox fully embraces message organization and management via tags/keywords called Topics. When you assign
            a topic to a message, it's also applied to other messages within the conversation, in addition to all future
            replies. Topics can be used for organization, views, search, and message filtering/rules.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-4">
          <h4>Gmail Labels</h4>
          <p>Postbox supports Gmail or GSuite labels and matches much of the organizational behavior of Google accounts
            online. All Mail and Important folders are also supported.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-1">
          <h4>Reminders + Pinning</h4>
          <p>Postbox lets you create reminders where it matters most — your Inbox! Create new reminders, annotate
            existing ones, and then pin reminders to the top of your message list so they're not forgotten.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box  fbox-bg-color-2">
          <h4>Smart Folders</h4>
          <p>A Smart Folder displays email messages that are stored in other folders and that meet certain criteria you
            specify. For example, a Smart Folder could include all the messages you receive from a specific person,
            regardless of which folders the messages are stored in.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-3">
          <h4>Social View</h4>
          <p>Postbox's Social view, located in the Focus Pane, lets you browse updates from your social networks,
            perfect for monitoring your brand, killing time during lunch, or when you're in power triage mode and need
            to clear out unnecessary clutter.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-4 mb-0">
          <h4>Subscription View</h4>
          <p>Another Focus Pane feature, the Subscription View organizes messages from the services you subscribe to and
            presents them in one convenient view. Great for unsubscribing from newsletters en masse.</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- Modal -->
<ng-template #emailmanagement let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="message-triageLabel">Email Management</h5>
    <button type="button" class="close btn p-0" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-1">
          <h4>Quick Post</h4>
          <p>Use Quick Post to forward email content to hundreds of applications and services, like Evernote, Slack,
            Todoist, and many more. Quick Post supports any service that can accept an email, or integration through
            Zapier or IFTTT.</p>
          <p>Best of all, Quick Post happens in the background, without the need for a compose window. You can even set
            your Filters to run Quick Post actions to create the ultimate workflow.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-2">
          <h4>Message Editing</h4>
          <p>Edit any message by pressing the Action button within the message header. Update the status of a Reminder
            without having to send a message to yourself. Change the message subject or any part of the message body,
            and when you're finished, you can either save your changes or create a copy to preserve the original.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-3">
          <h4>Quick Reply</h4>
          <p>Quick Reply lets you reply to a message without the need to open a compose window – perfect for short,
            snappy responses like "I'll see you at eight," or simply "Thanks!" And it all happens inline within a
            message or conversation.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-4 mb-0">
          <h4>Send & Archive</h4>
          <p>Supported in both Quick Reply and Compose windows, Send & Archive will automatically archive the message
            you're replying to – a great way to keep your inbox tidy.</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- Modal -->
<ng-template #composition let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="message-triageLabel">Composition</h5>
    <button type="button" class="close btn p-0" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-1">
          <h4>Responses + Placeholders</h4>
          <p>Responses are pre-canned text/HTML snippets that can be reused within message replies. Placeholders
            automatically populate with data from the message itself –or– your own data through the custom fields you
            create.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-2">
          <h4>70 Emails Every Business Needs</h4>
          <p>We're including 70 Professionally Written Email Responses that anyone can customize and use. You'll receive
            emails for finding a job, hiring, making introductions, reaching out to investors, sales, and more.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-3">
          <h4>Clips</h4>
          <p>Clips enable you to quickly insert pre-formatted HTML blocks into new messages. Similar to Signatures and
            Responses, Clips is a new content type that adds pro-styling to your messages without any coding.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-4">
          <h4>25 Beautifully Designed Email Signatures</h4>
          <p>As an added bonus, Postbox now includes 25 Beautifully Designed Email Signatures that will present you in
            the best light possible. We've included designs of all shapes, colors, and sizes. Each signature is fully
            customizable and contains all the code examples you need to make a lasting impression.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-1">
          <h4>HTML Editor</h4>
          <p>Postbox Code View provides full HTML visualization, editing, and fine-grained control over the messages you
            author, or the pre-canned Responses and Signatures you create.</p>
          <p>Code View is powered by CodeMirror, which supports syntax highlighting, auto-complete, and over 30 code
            themes to choose from.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box  fbox-bg-color-2">
          <h4>Image Scaling</h4>
          <p>The Image Scaling feature in Postbox 7 resizes large inline images with just a few clicks. Image scaling
            can be performed on a "per-image" basis, as opposed to the all or nothing approach found in some other email
            clients.</p>
          <p> Since ISPs have size limits for messages sent through their servers (for example, Gmail places a 25MB
            limit), Postbox can be set to display a warning if you exceed a certain threshold. The message size
            indicator will display the total message size, including text and images.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-3">
          <h4>Image Effects</h4>
          <p>Postbox contains a number of Image Effects that will enable you to create beautiful emails and newsletters.
            Effects include scaling, float, margin, flip, rotate, photo effects, frames, and shadow.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-4">
          <h4>Cloud File Sharing</h4>
          <p>Our Cloud File Sharing feature enables you to send links to your Dropbox, Box, and OneDrive files instead
            of sending the files themselves.</p>
          <p>Simply drag and drop a file from your storage folder into the body of a message and a link to that file
            will be created automatically.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-1 mb-0">
          <h4>Emojis</h4>
          <p>Emojis add personality to any email, and Postbox has the fastest and easiest way to insert Emojis into your
            messages. Simply type a colon, followed by the name of the Emoji you wish to use, then auto-complete against
            your desired selection. Fast, easy, and fun! 👍</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- Modal -->
<ng-template #search let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="message-triageLabel">Search</h5>
    <button type="button" class="close btn p-0" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-1">
          <h4>Quick Search</h4>
          <p>Built from the ground up to find anything and everything, fast. Find things in a flash with Postbox's
            super-speedy Search Bar, or customize your searches by sender, subject, date range, or other attributes.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-2">
          <h4>Document & Image Search</h4>
          <p>Can't find the document or photo? No worries! Postbox's Document and Image Search provides fast access to
            files hiding within your email messages — no more digging through email messages to find what you need!</p>
          <p>And once you've found what you're looking for, Postbox lets you save or send the file with just one click.
          </p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-3">
          <h4>Search by Contact</h4>
          <p>Click on any contact to instantly search for messages, documents, and images from that contact – either
            within the current folder or across all folders.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-4 mb-0">
          <h4>Compose Sidebar</h4>
          <p>The Compose Sidebar lets you find and use Contacts, Documents, and Images while you're composing a new
            message. Perform a quick search, then drag and drop what you need into your new message.</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- Modal -->
<ng-template #userinterface let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="message-triageLabel">Message Triage</h5>
    <button type="button" class="close btn p-0" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-1">
          <h4>Favorites Bar</h4>
          <p>Similar to the bookmarks or favorites bar in your web browser, the Postbox Favorites Bar provides quick
            access to your favorite accounts, folders or sub-folders.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-2">
          <h4>Tabbed Workspace</h4>
          <p>Open almost anything in a tab, including messages, folders, topics, contacts, attachments, and images.
            Quickly switch between and shuffle as needed.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-3">
          <h4>Conversation View</h4>
          <p>Postbox gathers message fragments from multiple folders and presents them in a unified view. Quickly bring
            yourself up to speed and jump into discussions with all the information and context you need to stay on top
            of your work.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-4">
          <h4>Inspector Pane</h4>
          <p>The Inspector Pane summarizes the content it finds in messages or conversations, including files, images,
            links, package tracking numbers, and more. It's like a discovery engine built right into your email.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-1">
          <h4>Vertical or Classic View</h4>
          <p>Display the message list in a vertical panel with message content on the right, or in a classic list with
            message content below.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box  fbox-bg-color-2">
          <h4>Themes</h4>
          <p>Postbox includes 24 Beautiful Themes choices across Light and Dark Modes.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-3">
          <h4>Theme Editor</h4>
          <p>Design your own email app using our powerful Theme Editor! Create, modify, delete, and import/export
            themes. Most importantly, you can change any color in the UI!</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-4 mb-0">
          <h4>Quick Folder Switch</h4>
          <p>Jump to another folder using your keyboard with Quick Switch. While viewing messages, type "g" to bring up
            the Quick Switch window, then start typing the name of the folder you would like to switch to, such as
            "Sent." Postbox will auto-complete and display corresponding accounts.</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- Modal -->
<ng-template #safetysecurity let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="message-triageLabel">Safety & Security</h5>
    <button type="button" class="close btn p-0" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-1">
          <h4>OpenPGP Encryption Enigmail Labs Project</h4>
          <p>Send and receive signed and encrypted messages using OpenPGP, powered by Enigmail, a 3rd-party Postbox Labs
            project.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-2">
          <h4>Anti-Tracking</h4>
          <p>To protect your privacy, Postbox 7 has an Anti-Tracking feature that will alert you when tracking tools are
            used within a message.</p>
          <p>Use Anti-Tracking to prevent pesky salespeople from knowing if, and when, you open a message.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-3">
          <h4>Phishing & Malware Protection</h4>
          <p>Postbox helps protect users from viruses, spyware, and trojan horses by checking URLs received in emails
            against a local database of suspected phishing or malware sites. The database is automatically updated every
            30 minutes, and if a potentially malicious URL is identified, a warning message is prominently displayed.
          </p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-4">
          <h4>Junk Mail Filtering</h4>
          <p>Postbox can filter junk mail from your Inbox, based on what it learns from you when you mark email messages
            as "junk" or "not junk."</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-1">
          <h4>Identities (Aliases)</h4>
          <p>You can use identities (also known as "aliases") to prevent junk mail from being sent to your primary email
            addresses. For example, you can use your primary email address for emailing friends and family, but use an
            email identity for online registrations, purchasing products, and joining mailing lists.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box  fbox-bg-color-2 mb-0">
          <h4>Customizable Display Names</h4>
          <p>Show contacts by "Display Name," "Email Address," or "Display Name and Email Address" for contact
            presentation in the message list and message header envelope. By showing the email address, you can more
            quickly determine the legitimacy of the sender.</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- Modal -->
<ng-template #systemfeatures let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="message-triageLabel">System Features</h5>
    <button type="button" class="close btn p-0" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-1">
          <h4>Windows Minimize to Tray</h4>
          <p>Keep Postbox minimized and running in the background on Windows using a handy tray icon.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-2">
          <h4>macOS Contacts App Integration</h4>
          <p>Postbox provides read and write integration with the macOS "Contacts" application, so Mac users can keep
            all of their contacts in one location.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-3">
          <h4>macOS Touch Bar</h4>
          <p>Postbox supports the macOS Touch Bar for newer MacBook Pro laptops. The Touch Bar actions are both unique
            to Postbox and common to other apps for functions such as bold, italic, etc.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-4">
          <h4>macOS Share Menu</h4>
          <p>Postbox provides sharing functionality for some of the most commonly used apps on macOS, including the
            macOS Contacts, Safari, Photos, Calendar, Finder, Pages, Numbers, and Keynote.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-1 mb-0">
          <h4>Integration with Things and OmniFocus</h4>
          <p>Postbox provides first-class integration support for Things, OmniFocus, and Evernote. Create new to-dos or
            project entries in a flash.</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- Modal -->
<ng-template #general let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="message-triageLabel">General</h5>
    <button type="button" class="close btn p-0" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-1">
          <h4>Keyboard Shortcuts</h4>
          <p>For our power users, Postbox supports a full suite of keyboard shortcuts. Postbox also supports an
            alternative suite that maps to Gmail's keyboard shortcuts.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-2">
          <h4>IMAP Limiting</h4>
          <p>Limit the number of messages that will download per folder to keep your message list clean.</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-3">
          <h4>Labs</h4>
          <p>Similar to add-ons, our Labs feature will allow for experimental 3rd-party code to be used within Postbox.
          </p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-4">
          <h4>RSS & Newsgroups</h4>
          <p>RSS and Newsgroup reader support is included, along with our favorite message type – email!</p>
        </div>
      </div>
      <div class="col-md-12 col-lg-4">
        <div class="content-box fbox-bg-color-1 mb-0">
          <h4>Server Support</h4>
          <p>Postbox supports IMAP, POP3, and SMTP servers.</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
