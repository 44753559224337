import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, distinctUntilChanged, tap } from 'rxjs/operators';

import * as SyncStateActions from './sync-state.actions';
import { Action, Store } from '@ngrx/store';
import { AppState } from '..';

@Injectable()
export class SyncStateEffects {
  syncState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SyncStateActions.syncState),
      map(() => {
        const storageValue = localStorage.getItem('state');
        if (storageValue) {
          try {
            const state = JSON.parse(storageValue);
            return SyncStateActions.syncStateSuccess({ state });
          } catch {
            localStorage.removeItem('state');
          }
        }
        return SyncStateActions.syncStateFailure();
      })
    )
  );

  serialize$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          SyncStateActions.syncStateSuccess,
          SyncStateActions.syncStateFailure
        ),
        switchMap(() => this.store),
        distinctUntilChanged(),
        tap((state) => localStorage.setItem('state', JSON.stringify(state)))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private store: Store<AppState>) {}

  ngrxOnInitEffects(): Action {
    return SyncStateActions.syncState();
  }
}
