import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuth from './auth.reducer';

export const selectAuthState = createFeatureSelector<fromAuth.State>(
  fromAuth.authFeatureKey
);

export const getToken = createSelector(
  selectAuthState,
  (state) => state.access_token
);

export const getIsAuthenticated = createSelector(
  selectAuthState,
  (state) => state.isAuthenticated
);