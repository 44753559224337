import { createAction, props } from '@ngrx/store';
import { user } from 'src/app/shared/models/profile.model';

export const getUser = createAction('[Settings] getUser');

export const getUserSuccess = createAction(
  '[Settings] Get User Success',
  props<{ user: user }>()
);

export const getUserFailure = createAction(
  '[Settings] Get User Failure',
  props<{ error: any }>()
);
