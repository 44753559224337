import { Action, ActionReducer } from '@ngrx/store';
import { AppState } from '..';
import * as SyncStateActions from './sync-state.actions';

export const syncStateFeatureKey = 'syncState';

export interface State {}

function isSyncStateSuccess(
  action: Action
): action is ReturnType<typeof SyncStateActions.syncStateSuccess> {
  return action.type === SyncStateActions.syncStateSuccess.type;
}

export const synStateMetaReducer = (
  reducer: ActionReducer<AppState>
): ActionReducer<AppState> => {
  return (state, action) => {
    if (isSyncStateSuccess(action)) {
      return action.state;
    } else {
      return reducer(state, action);
    }
  };
};
