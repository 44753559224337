import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/http/auth.service';
import { ExceptionMessage } from 'src/app/shared/models/exception-message.model';
import { AppState } from 'src/app/store';
import { isLoading } from 'src/app/store/loader/loader.actions';
import { loading } from 'src/app/store/loader/loader.selectors';
import { snackBarOpen } from 'src/app/store/snack-bar/snack-bar.actions';
import { MustMatch } from '../_helpers/must-match.validator';
import { MemoMustMatch } from '../_helpers/word-match.validators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  show!: boolean;
  resetPasswordForm!: FormGroup;
  submitted = false;
  loading$!: Observable<boolean>;
  hide = true;
  hide1 = true;
  recoveryDetails: any;
  exceptionMessage: ExceptionMessage = new ExceptionMessage();
  recoveryId: any;
  uuid: any;
  verifyId: any;
  pwdInfo: any;
  mempwdInfo: any;
  chkverify!: number;
  userId: boolean = false
  constructor(
    private modalService: NgbModal,
    private store: Store<AppState>,
    private formBuilder: FormBuilder,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private authService: AuthService
  ) {
    // this.recoveryId = this.activateRoute.snapshot.params.recoveryId;
    // console.log(this.recoveryId);

    // this.resetPasswordForm.controls['emailId'].enable();
    this.verifyId = sessionStorage.getItem('resetLink')
    this.chkverify = 1;
    if (sessionStorage.getItem('resetLink') != '' && this.chkverify === 1) {
      this.authService.getalluserByRecoverId(this.verifyId).subscribe((data: any) => {
        this.exceptionMessage = data;
        if (data.status.toLowerCase() == 'success') {
          // this.removeData()
          this.recoveryDetails = data.response[0];
          //  this.resetPasswordForm.controls['emailId'].disable();
          // this.resetPasswordForm.reset()
          if (this.recoveryDetails.memorableWord) {
            this.resetPasswordForm.get('memorableWord')?.setValidators([Validators.required, Validators.maxLength(20), Validators.minLength(8), Validators.pattern("([a-zA-Z]+( [a-zA-Z]+)*)")]);
            this.resetPasswordForm.get('conmemorableWord')?.setValidators([Validators.required,])
            this.resetPasswordForm.get('hintWord')?.setValidators([Validators.required,Validators.maxLength(20),Validators.minLength(8),Validators.pattern(/^[a-z]+$/i)])
          //   // {
          //   //   Validators:MemoMustMatch('memorableWord','conmemorableWord') 
          //   // }
          // }
          // } else {                
          //     this.resetPasswordForm.get('description')?.clearValidators();               
          }
          if(this.recoveryDetails.password){
            this.resetPasswordForm.get('password')?.setValidators([Validators.required,Validators.minLength(8), Validators.maxLength(16),Validators.pattern('(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!#^~%*?&,.<>"\'\\;:\{\\\}\\\[\\\]\\\|\\\+\\\-\\\=\\\_\\\)\\\(\\\)\\\`\\\/\\\\\\]])[A-Za-z0-9\d$@].{7,15}')])
            this.resetPasswordForm.get('confirmPassword')?.setValidators([Validators.required,])
           }
          this.resetPasswordForm.patchValue({
            'emailId': this.recoveryDetails.emailId,
          });
          // this.resetPasswordForm.controls['emailId'].enable();
          //  const msg = data.message;
          //  this.store.dispatch(
          //    snackBarOpen({
          //      message: msg,
          //      config: {
          //        horizontalPosition: 'end',
          //        verticalPosition: 'top',
          //        panelClass: ['snack-bar-success'],
          //      },
          //    }))

        }
        else if (data.status.toLowerCase() == 'Failed') {
          // this.removeData()
          const msg = data.message;
          this.store.dispatch(
            snackBarOpen({
              message: msg,
              config: {
                horizontalPosition: 'end',
                verticalPosition: 'top',
                panelClass: ['snack-bar-failure'],
              },
            }))
          this.router.navigate(['/sign-in']);
        }
        else {
          this.store.dispatch(isLoading({ isLoading: false }));
          // this.removeData()
          const msg = this.exceptionMessage.message
            ? this.exceptionMessage.message
            : 'Something went wrong';
          this.store.dispatch(
            snackBarOpen({
              message: msg,
              config: {
                horizontalPosition: 'end',
                verticalPosition: 'top',
                panelClass: ['snack-bar-failure'],
              },
            })
          );
          this.router.navigate(['/sign-in']);
        }
      });
    }
    this.loading$ = this.store.pipe(select(loading));
    this.show = false;
    this.resetPasswordForm = this.formBuilder.group(
      {
        // recoveryId: [this.recoveryId],
        emailId: ['', Validators.required],
        hintWord: ['', this.recoveryDetails?.memorableWord ? [Validators.required,Validators.maxLength(20),Validators.minLength(8),Validators.pattern(/^[a-z]+$/i)] : []],
        memorableWord: ['', this.recoveryDetails?.memorableWord ? [Validators.required,Validators.maxLength(20),Validators.minLength(8),Validators.pattern(/^[a-z]+$/i)] : []],
        conmemorableWord: ['', this.recoveryDetails?.memorableWord ? [Validators.required,] : []],
        recoverEmail: ['', this.recoveryDetails?.userName ? [Validators.required] : []],
        recoveryId: ['', this.recoveryDetails?.userName ? [Validators.required] : []],
        password: ['', this.recoveryDetails?.password ?  [Validators.required,Validators.minLength(8), Validators.maxLength(16),Validators.pattern('(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!#^~%*?&,.<>"\'\\;:\{\\\}\\\[\\\]\\\|\\\+\\\-\\\=\\\_\\\)\\\(\\\)\\\`\\\/\\\\\\]])[A-Za-z0-9\d$@].{7,15}')] : []],
        // password:  ['', this.recoveryDetails?.password ? [Validators.required,Validators.maxLength(20),Validators.minLength(8)] : []],
        confirmPassword: ['', this.recoveryDetails?.password ? [Validators.required] : []],
      },
      {
        

        validator: [
        MustMatch('password', 'confirmPassword'),
        MustMatch('memorableWord', 'conmemorableWord'),
        ]
      }
     

    );
  }

  ngOnInit(): void {
  }

  open(pwdInfo: any) {
    this.modalService.open(pwdInfo, { centered: true,backdrop: 'static',keyboard: false, modalDialogClass: 'reginfo-modal' });
  }

  memOPen(mempwdInfo: any) {
    this.modalService.open(mempwdInfo, { centered: true,backdrop: 'static',keyboard: false, modalDialogClass: 'reginfo-modal' });
  }

  removeData() {
    sessionStorage.removeItem(this.verifyId);
    // sessionStorage.clear();
  }
  get f() {
    return this.resetPasswordForm.controls;
  }
  get password() {
    return this.resetPasswordForm.get('password');
  }
  resetPassword() {
    // this.resetPasswordForm.controls['emailId'].enable();
    this.submitted = true;

    console.log(this.resetPasswordForm.value)
    // console.log( this.verifyId ) 
    // var recoverymail = sessionStorage.getItem(' this.verifyId');
    console.log(this.resetPasswordForm.valid);

    if (this.resetPasswordForm.valid) {
      // alert('hai')
      // var token = sessionStorage.getItem('token');
      // const { recoveryId, userEmailId, userRecoveryEmailId, password } =
      //   this.resetPasswordForm.value;
      this.store.dispatch(isLoading({ isLoading: true }));
      console
      this.authService.setPassword(this.resetPasswordForm.value).subscribe((res) => {
        if (res.status == 'Success') {
          this.store.dispatch(isLoading({ isLoading: false }));
          const msg = res.message
          this.store.dispatch(
            snackBarOpen({
              message: msg,
              config: {
                horizontalPosition: 'end',
                verticalPosition: 'top',
                panelClass: ['snack-bar-success'],
              },
            })
          );
          this.router.navigate(['/sign-in']);
        }
        else {
          res.status == 'Failed'
          this.store.dispatch(isLoading({ isLoading: false }));
          res.status == 'Failed'
          this.store.dispatch(
            snackBarOpen({
              message: res.message,
              config: {
                horizontalPosition: 'end',
                verticalPosition: 'top',
                panelClass: ['snack-bar-failure'],
              },
            })
          );
        }
      });
    }
  }
}
