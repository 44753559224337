<div class="terms-and-conditions-page">
  <section>
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <h3>Terms and Conditions</h3>
          <h4>Terms of Use</h4>
          <ul class="mt-0">
            <li>You agree that you will only use this service platform (website) in the good faith and will not provide
              false or misleading information about yourself. You agree that you will not do anything to throttle,
              engineer a denial of service, or in any other manner impair the performance or functionality of our
              website.
              You agree that you will not tamper with, reverse- engineer or otherwise use the website for any purpose
              for
              which is not intended including, but not limited to accessing information about registered user
              store/shared/mention in our website, identifying or attempting to identify other registered user or
              gaining
              or attempting to gain access to the cloud database of the service.</li>
            <li>ONPASSIVE has offered various services/tools/facilities to the Founder/Customer and Founder/Customer has
              accepted the terms and conditions of the ONPASSIVE. Founder/Customer will have access to the Confidential
              and Proprietary Information of the ONPASSIVE after read and accepts the term of use. The use of
              Confidential
              Information by the FOUNDER/CUSTOMER shall be treated in Confidence.</li>
            <li>ONPASSIVE Confidential Information, Proprietary Information, Invention, Patents, Copyrights, Trademark
              and
              other Intellectual Property Rights are important assets of the ONPASSIVE</li>
            <li>ONPASSIVE Confidential Information, shall also mean any and all information available to ONPASSIVE and
              which is not available in a published form at the Effective Date, including, but not limited to, research
              ideas, research results, research directions, patents, patent applications, and patent ideas, trade
              secrets,
              business models, business forecasts, marketing strategies, financial data, customer lists, investors,
              contractual relationships, manufacturing proprietary information and documentation, ideas, schematics,
              sketches, models, know-how, marketing campaigns algorithms, processes, formulae whether or not
              specifically
              stated or define in subsequent paragraphs.</li>
            <li>It is Important for the ONPASSIVE to protect Its Intellectual Property, Confidential and Proprietary
              Information to the fullest extent and the FOUNDER/CUSTOMER is willing to comply with following provisions,
              as part of the Founder/Customer’s contract of service/ facility with ONPASSIVE.</li>
            <li>The Term of use shall be binding in the principle as below; the binding effects of the Term of use shall
              not be affected by length of service/facilities between the parties, the reason for terminating the
              service/facilities relationship between parties and amount of the FOUNDER/CUSTOMER’s payment paid by
              ONPASSIVE. The FOUNDER/CUSTOMER shall be liable to his/her obligations under the Term of use after the
              termination of the Services/facilities for whichever reasons.</li>
            <li>No Waiver of breach, failure of condition, or any right or remedy contained in or granted by the
              provision
              of this Term of use shall be effective unless it is in writing and signed by the party waiving the breach,
              failure, right or remedy. No waiver by the ONPASSIVE of any breach, failure, right or remedy shall be
              deemed
              a waiver of any other breach, failure, right or remedy, whether or not similar, nor shall any waiver
              constitute a continuing waiver unless the writing so specifies.</li>
            <li>The Term of use and all of its exhibits constitutes the entire Term of use agreed upon and by the
              parties
              and supersedes all prior oral or written negotiations, representations or Term of uses reached by the
              Parties relating to the subject matter of this Term of use.</li>
            <li>If a Court of Competent jurisdiction holds any provision of this Term of use to be illegal,
              unenforceable
              or invalid in whole or in part for any reason, the validity and enforceability of the remaining provisions
              or portion of them, shall not be affected.</li>
            <li>If a Court of Competent jurisdiction holds any provision of this Term of use to be illegal,
              unenforceable
              or invalid in whole or in part for any reason, the validity and enforceability of the remaining provisions
              or portion of them, shall not be affected.</li>
            <li>ONPASSIVE have legal right to change, modifies, amend, addendum any clause of this Term of use without
              any
              prior approval.</li>
          </ul>
          <h4>Prohibition to Use</h4>
          <h5>Except for the express written consent of ONPASSIVE, FOUNDER/CUSTOMER agrees:</h5>
          <p>Not to use or disclose to another person or entity any confidential information of ONPASSIVE.</p>
          <ul>
            <li>Not to make, or cause to be made, any copies, facsimiles or other reproductions including data files of
              any documents containing confidential information of ONPASSIVE; and</li>
            <li>To use all other reasonable means to maintain the secrecy and confidentiality of the confidential
              information of ONPASSIVE.</li>
            <li>Data of or about the ONPASSIVE or its vendors, customers, Founder/Customers, advisors, mentors, service
              providers or consultants, in particular, contact information and sales information</li>
            <li>Data about ONPASSIVE’s compliance with applicable law including data about licenses, permissions,
              approvals, permissions or consents applied for, requested by, granted to or denied to the ONPASSIVE or its
              promoters</li>
            <li>Data about all filings and official submissions made by the ONPASSIVE to governmental authorities and
              the
              content of the discussion and communication by the ONPASSIVE with such authorities</li>
          </ul>
        </div>
        <div class="col-lg-6">
          <img src="/assets/images/terms-conditions.png" class="img-fluid" alt="terms-conditions" />
          <ul>
            <li>Data related to the ONPASSIVE’s business, its existing and upcoming products, services, business
              strategy,
              Marketing Campaigns details, terms of engagement with its vendors or customers, pricing data, payment and
              refund policies and plan, business plans, users/founders/customers data, policies and plans which directly
              and indirectly related to the ONPASSIVE.</li>
            <li>Any data, documents, sketches, designs, plans, drawings, photographs, reports, communication, technical
              information, information about Intellectual Property Rights, user information, compilation, subscription
              details, asset information, know-how, research and development, internal policies</li>
            <li>Any information related to the ONPASSIVE’s technology, software, hardware, code, design, business
              strategy, business plan, internal systems, and business architecture</li>
            <li>Financial data, in particular, concerning budgets, fees and revenue calculations, sales figures, bonus
              plans, financial statements, profit expectations and inventories of the ONPASSIVE</li>
            <li>Training data, particularly documents, videos, webinars, photographs, website data, processes,
              multimedia
              files, presentations and any such training resources that the Founder/Customer gains access to during his
              association with the ONPASSIVE</li>
            <li>Security information (including passwords, login credentials) used to access any resource owned or
              operated by the ONPASSIVE, its affiliates, clients or third-party agents </li>
          </ul>
          <h5>Client or user data, user credits, user analytics, user preferences, feedback information:</h5>
          <ul>
            <li>Any information which may be reasonably understood by its nature, or by the context of its disclosure,
              to
              be confidential; and</li>
            <li>Any information derived from any of the above-mentioned information. and</li>
            <li>Original information supplied by the ONPASSIVE or information provided to the ONPASSIVE by third parties
              which the ONPASSIVE is obligated to keep confidential</li>
            <li>Prohibition of postings about other companies or other business opportunities or programs, including
              their
              links.</li>
            <li>Prohibition of sharing posting and comment that reflects a race, sex, cast, religion, political agenda,
              team competition and cross-recruiting as well,</li>
            <li>Prohibition of vigorous and impassioned sharing of ideas, negative languages,
              webinar/videos/community/data of the ONPASSIVE</li>
            <li>Participate in constructive online dialogues and use our best judgment when posting to external or
              internal social media.</li>
          </ul>
          <h4>Obligations</h4>
          <ul>
            <li>The Founder/Customer agrees and acknowledges that during the term of this act with the ONPASSIVE the
              Founder/Customer shall have access to Confidential Information through oral, visual, electronic or written
              means.</li>
            <li>The Founder/Customer understands and acknowledges that the Confidential Information is of immense value
              to
              the ONPASSIVE and its Affiliates and/or its present, past or prospective clients. The Founder/Customer
              understands that any use or disclosure of such Confidential Information including any inadvertent
              disclosure
              can cause immense and irreparable harm, loss, damage and injury to the ONPASSIVE and its Affiliates and
              its
              reputation and hence undertakes to keep such Confidential Information confidential.</li>
            <li>The Founder/Customer agrees and undertakes that at all times during the term of this Term of use and
              thereafter on termination of this Term of use for whatever reason to hold in the strictest confidence,
              andnot to use, except for the benefit of the ONPASSIVE and its Affiliates, and absolutely refrain from in
              any manner divulging, discussing, disclosing, or otherwise releasing, the Confidential Information to any
              third party or in any manner directly or indirectly using the Confidential Information without the written
              authorization of the ONPASSIVE.</li>
            <li>The Founder/Customer recognizes that the ONPASSIVE and its Affiliates have received and, in the future,
              will receive from third parties, information that would be confidential and proprietary in nature to such
              third parties, during the term of use.</li>
          </ul>
          <h4>Payment Policy</h4>
          <p>Founder/Customer shall eligible for our various service/facilities after making valid/authorized payment
            (through the modes as explained/mentioned in our website). Payment policies and structure are defined by
            ONPASSIVE at its sole discretion and it will change periodically as per business strategies and plans (as
            required).</p>
        </div>
      </div>
    </div>
  </section>
</div>
