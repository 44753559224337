import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store';
import { autoLogin, login, logout } from 'src/app/store/auth/auth.actions';
import {environment} from '../../../../environments/environment'

@Component({
  selector: 'app-omail-auto-login',
  templateUrl: './omail-auto-login.component.html',
  styleUrls: ['./omail-auto-login.component.scss']
})
export class OmailAutoLoginComponent implements OnInit {
  omailUserId: string = "";
  omailapipath: string = "";
  isEncrypted: any;
  constructor(
    private aRoute:ActivatedRoute,
    private _http:HttpClient,
    private store: Store<AppState>) {
      this.aRoute.queryParams.subscribe((qparams: any) => {
        console.log(qparams)
      })
     }

  ngOnInit(): void {
    this.store.dispatch(logout({ message: '' }));
    
    this.aRoute.queryParams.subscribe((qparams: any) => {
      this.omailUserId = qparams.UserName;
      // this.isEncrypted = (atob(qparams.encrypted).toLowerCase() === "yes");
      // this.isEncrypted = (params.params.encrypted.toLowerCase() === "true");
      this.checkUser();
    })
  }
  checkUser(){
    const payload:any = {
      // password: "string",
      encrypted: false,
      userName: this.omailUserId
    };
    // this._http.post(`${environment.apiUrl}encryptCredentials`,payload).subscribe((res:any)=>{
    //   const payload = {
    //     email:res.userName,
    //     password:res.password,
    //   }
      this.store.dispatch(autoLogin(payload));
    // })
  }
}
