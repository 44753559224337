import { Component, Directive, EventEmitter, OnInit, Output, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { interval, Observable } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';
import { AuthService } from 'src/app/core/http/auth.service';
import { PasswordStrengthValidator } from 'src/app/modules/password-strength.validators';
import { CounterDirective } from 'src/app/modules/timer.directive';
// import { PasswordStrengthValidator } from 'src/app/modules/password-strength.validators';
import { AppState } from 'src/app/store';
import { logout, validateEmail } from 'src/app/store/auth/auth.actions';
import { isLoading } from 'src/app/store/loader/loader.actions';
import { loading } from 'src/app/store/loader/loader.selectors';
import { snackBarOpen } from 'src/app/store/snack-bar/snack-bar.actions';

export declare type NgxOtpStatus = 'success' | 'error' | null;


export declare enum NgxOtpBehavior {
  DEFAULT = 0,
  LEGACY = 1
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  count = 30;

  timeout = setInterval(() => {
    if (this.count > 0) {
      this.count -= 1;
    } else {
      clearInterval(this.timeout);
    }
  }, 500);


  private maxValue = 90;

  countDown$ = interval(500).pipe(
    map(value => this.maxValue - value),
    takeWhile(x => x >= 0)
  );

  show: boolean;
  // memorableForm: FormGroup;
  loginForm: FormGroup;
  otpForm: FormGroup;

  otpValidModel = new OtpValidModel()
  memorablemodel = new MemorableModel()
  submitted = false;
  hide = true;
  otpform: boolean = false;
  memorabale = false;
  logingotm = true
  loading$: Observable<boolean>;
  otpsubmitted = false;
  generatedNumber: any;
  lotto = [];
  thunder = [];
  euro = [];
  gameArray = [];
  numbers1: any[] = [];
  numbers: any[] = [];
  keys: any[] = [];
  keysValues: any[] = [];


  randomNumber!: number;
  toPick: any;
  topNum: any;
  bonus: any;
  bonusPick: any;
  bonusNum: any;
  myForm: any = FormGroup
  fb: any;
  wordsubmitted = false;
  enteredValue: any;
  value: any[] = [];
  isMemorable: any;
  isOtp: any;
  otp: any;
  // rows: any;
  form: any = FormGroup;
  formInput = ['input2', 'input1', 'input3', 'input6', 'input4', 'input5'];
  formInputOtp = ['1', '2', '3', '4'];
  @ViewChildren('formRow') rows: any;
  usernameLength: any;
  verifyId: any;
  chkverify: any;
  passwordattamts: any;
  defaultValue: any;

  // length: any;

  // btoa: number;
  // value: string;
  @ViewChildren(CounterDirective) CounterDirective;
  @Output() onValueChange = new EventEmitter<any>();
  timer1!: NodeJS.Timeout;
  display: any;
  public timerInterval: any;
  showMsg: boolean = false;
  notice = 'otp is expired click on Resend otp';
  showNotice: boolean = false;
  useremail: any;
  constructor(
    private store: Store<AppState>,
    private authservice: AuthService,
    private formBuilder: FormBuilder,
    private activateRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router,


  ) {

    this.verifyId = sessionStorage.getItem('verifyLink')
    this.chkverify = 1;

    if ((sessionStorage.getItem('verifyLink') != '' && sessionStorage.getItem('verifyLink') != null) && this.chkverify === 1) {
      this.authService.getverifyDetailsByRecoverId(this.verifyId).subscribe((data) => {
        console.log(data)

        console.log(this.removeData())
        if (data.status.toLowerCase() == 'success') {
          this.removeData()
          const msg = data.message;
          this.store.dispatch(
            snackBarOpen({
              message: msg,
              config: {
                horizontalPosition: 'end',
                verticalPosition: 'top',
                panelClass: ['snack-bar-success'],
              },
            }))


        }
        else if (data.status.toLowerCase() == 'failure') {
          this.removeData()
          const msg = data.message;
          this.store.dispatch(
            snackBarOpen({
              message: msg,
              config: {
                horizontalPosition: 'end',
                verticalPosition: 'top',
                panelClass: ['snack-bar-failure'],
              },
            }))
        }
        // else{
        //   const msg = 'ALREADY_LINK_VERIFIED ';
        //   this.store.dispatch(
        //     snackBarOpen({
        //       message: msg,
        //       config: {
        //         horizontalPosition: 'end',
        //         verticalPosition: 'top',
        //         panelClass: ['snack-bar-failure'],
        //       },
        //     }))

        // }

      });
    }


    // this.store.dispatch(logout({ message: 'login' }));
    this.removeData()
    this.loading$ = this.store.pipe(select(loading));
    this.show = false;
    this.loginForm = this.formBuilder.group({


      email: [
        '',
        [Validators.email, Validators.required]

      ],
      // password: ['null',undefined, Validators.compose([
      //   Validators.required, Validators.minLength(8), PasswordStrengthValidator])]
      // PasswordStrengthValidator
      password: ['', [Validators.required, Validators.pattern('^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$')]],
      // password: ['', Validators.compose([
      // Validators.required, PasswordStrengthValidator])]

    });
    // this.loginForm.get("password")
    console.log(this.loginForm.get("password"));

    this.otpForm = this.formBuilder.group({
      otp: ['', Validators.required],
      keys: ['', Validators.required]
      // keys:
    });

  }
  /*
   *  Clearing the local and session storage 
  */
  removeData() {
    sessionStorage.clear();
    localStorage.clear();
  }

  ngOnInit(): void {
    this.loginForm.controls.email.patchValue(this.defaultValue);
    this.loginForm.controls.password.patchValue(this.defaultValue);
    this.form = this.toFormGroup(this.formInputOtp);

    this.timer(5)
  }

  timer(minute: number) {
    let seconds: number = 1 * 60;
    let textSec: any = '0';
    let statSec: number = 60;

    const prefix = minute < 10 ? '' : '';

    this.timer1 = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        clearInterval(this.timer1);
        this.showMsg = true;
        this.showNotice = true;

        this.otpValidModel.email = this.loginForm.get('email')?.value;
        this.otpValidModel.otp = this.otpForm.value;
        const otpvalue = this.otpValidModel;
        console.log(otpvalue)
        console.log(this.otpValidModel.email)
        // this.refreshtimer()
        // this.authservice.expiredotp(otpvalue).subscribe((res: any) => {
        //   console.log(res)
        //   if(res.status.toLowerCase() == 'success') {

        //     const msg = res.message;
        //     this.store.dispatch(
        //       snackBarOpen({
        //         message: msg,
        //         config: {
        //           horizontalPosition: 'end',
        //           verticalPosition: 'top',
        //           panelClass: ['snack-bar-failure'],
        //         },
        //       }))

        // }
        // })



      }
    }, 1000);
  }
  // refreshtimer(){
  //   this.timer(5)
  // }
  ResendOtp() {
    clearInterval(this.timer1);
    this.timer(1);

    if (this.loginForm.valid) {
      this.store.dispatch(isLoading({ isLoading: true }));
      this.authservice.login(this.loginForm.value).subscribe((res: any) => {
        console.log(res.response)
        this.passwordattamts = res.response
        this.loginForm.controls.password = res.response
        // console.respoce
        console.log(res.message)
        if (res.status.toLowerCase() == 'success') {
          const msg = 'Otp Sent sucessfully';
          this.store.dispatch(
            snackBarOpen({
              message: msg,
              config: {
                horizontalPosition: 'end',
                verticalPosition: 'top',
                panelClass: ['snack-bar-success'],
              },
            }))

          // this.otpform  = true;
          // this.memorabale = !this.otpform


        }

        // this.store.dispatch(isLoading({ isLoading: false }));
        this.loading$ = this.store.pipe(select(loading));
        this.submitted = false;
        // this.loginForm.reset();

        // this.loginForm.controls.email.reset();
        // this.loginForm.controls.password.reset();
      }
      )
    }

  }
  refresh() {
    let currentUrl = decodeURIComponent(this.router.url);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
      console.log(currentUrl, "Current navigation")
    });

  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  get f() {
    return this.loginForm.controls;
  }

  get fp() {
    return this.otpForm.controls;
  }
  role: any
  onSubmit() {
    this.submitted = true;

    console.log(this.loginForm);
    // this.keysValues = [];
    if (this.loginForm.valid) {
      this.store.dispatch(isLoading({ isLoading: true }));
      this.authservice.login(this.loginForm.value).subscribe((res: any) => {
        console.log(res.response)
        this.passwordattamts = res.response
        this.loginForm.controls.password = res.response
        // console.respoce
        console.log(res.message)
        if (res.status.toLowerCase() == 'success') {

          this.otpform = res.response[0].isOtp
          this.memorabale = res.response[0].isMemorable
          this.usernameLength = res.response[0].length
          this.logingotm = false
          this.keys = [];
          this.keysValues = [];
          this.form = this.toFormGroup(this.formInputOtp);
          this.role = res.response[0].role
          // sessionStorage.setItem('role',this.role)

        }

        else if (res.status.toLowerCase() == 'failure') {

          const msg = res.message;
          this.store.dispatch(
            snackBarOpen({
              message: msg,
              config: {
                horizontalPosition: 'end',
                verticalPosition: 'top',
                panelClass: ['snack-bar-failure'],
              },
            }))
          setTimeout(() => {
            this.refresh()
          }, 1000);

          //  this.loginForm.reset()

        }

        if (this.memorabale) {
          let min, max, r, n, p;
          min = 1;
          max = this.usernameLength;
          r = 3; // how many numbers you want to extract

          for (let i = 0; i < r; i++) {
            do {
              n = Math.floor(Math.random() * (max - min + 1)) + min;
              p = this.numbers1 ? this.numbers1.includes(n) : 0;
              if (!p) {
                this.numbers1.push(n);
              }
            }
            while (p);
          }

          const str = this.numbers1.toString();
          this.numbers = str.split(',');
          this.formInput = this.numbers
          console.log('backToArr: ', this.numbers, ',', this.formInput);
          this.form = this.toFormGroup(this.formInput);
          console.log(this.formInput.join(" - "));

        }


        this.store.dispatch(isLoading({ isLoading: false }));
        this.submitted = false;
        // this.loginForm.reset();

        // this.loginForm.controls.email.reset();
        // this.loginForm.controls.password.reset();
      }
      )
    }



    if (this.loginForm.controls.password.value == '' || this.loginForm.controls.email.value == '') {
      const error = 'Please Enter Correct User ID and Password';
      this.store.dispatch(
        snackBarOpen({
          message: error,
          config: {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            panelClass: ['snack-bar-failure'],
          },
        })
      );
    }
  }
  keyUpEvent(event, index) {

    console.log(event, '', index)
    let pos = index;
    if (event.keyCode === 8 && event.which === 8) {
      pos = index - 1;
    } else {
      pos = index + 1;
    }
    if (this.memorabale) {
      if (pos > -1 && pos < this.formInput.length) {
        this.rows._results[pos].nativeElement.focus();
      }

    } else {
      if (pos > -1 && pos < this.formInputOtp.length) {
        this.rows._results[pos].nativeElement.focus();
      }
    }

  }

  toFormGroup(elements) {
    const group: any = {};

    elements.forEach(key => {
      group[key] = new FormControl('', Validators.required);
    });
    return new FormGroup(group);
  }

  otpSubmit() {

    this.keysValues = [];
    this.keys = [];
    Object.keys(this.form.value).forEach(key => {
      console.log(key)
      if (this.memorabale) {
        this.keys.push(key);
        this.keysValues.push(this.form.value[key])


      }
      else {
        console.log(this.form.value[key])
        if (this.form.value[key] == '') {
          console.log(this.form.value[key])
          if (onmessage) {

          }

        }
        else {
          this.keysValues.push(this.form.value[key])

        }


      }

    })

    this.otpValidModel.email = this.loginForm.get('email')?.value;
    this.otpValidModel.password = this.loginForm.get('password')?.value;
    this.otpsubmitted = true;
    // const { email,  isMemorable,isOtp,keys,length,values } = this.otpForm.value;
    this.otpValidModel.otp = this.memorabale ? '' : this.keysValues.join('');
    this.otpValidModel.isMemorable = this.otpForm.get('isMemorable')?.value;
    this.otpValidModel.isMemorable = this.memorabale;
    // this.otpValidModel.role=this.role
    this.otpValidModel.keys = this.keys;
    this.otpValidModel.values = this.memorabale ? this.keysValues : [];

    // this.otpValidModel.keys=this.otpForm.get('keys')?.value;
    // this.otpValidModel.values=this.otpForm.get('values')?.value;

    this.store.dispatch(validateEmail({
      email: this.otpValidModel.email
      , isMemorable: this.otpValidModel.isMemorable, role: this.role, otp: this.otpValidModel.otp, keys: this.otpValidModel.keys, length: 0, values: this.otpValidModel.values,
    }));
    // }

  }



}
// }

export class OtpValidModel {
  "email": string;
  "password": string;
  "isMemorable": boolean;
  "otp": any;
  "keys": any;
  "length": number;
  "values": any;
  role: any;
}
export class MemorableModel {
  "email": string;
  "password": string;
  "isMemorable": true;
  "isOtp": false;
  "keys": any;
  "length": any;
  "values": any;
}
