import { Injectable } from '@angular/core';
import { is } from 'date-fns/locale';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComposedataService {

  // constructor() { }
  public stringSubject = new Subject<string>();

  private mySub = new Subject<number>();
  private data: any = {};
  strToArr: any;
  composeDataObservable$ = this.mySub.asObservable();

  setOption(option: any, value: any) {
    // console.log(option,value);
    (typeof value === "string") ? this.strToArr = value.split(",") : this.strToArr = value

    this.data[option] = this.strToArr;
  }


  getOption() {
    return this.data;
  }


}
