<div class="sign-fm sigup-fm">
  <div class="logo">
    <a routerLink="/" class="logo"><img src="/assets/images/fm-logo.svg" routerLink="/" alt="O-Mail" /></a>
  </div>
  <div class="form-header">
    <h3 >Register <span class="reg-info" (click)="open(reginfo)">
      <img src="../../../../../assets/images/loginflow/reg_info.png" alt="reg_info" /></span></h3>
    <!-- <h3>Register 
        <span  class="reg-info"  (mouseenter)="getRelatedElement($event,reginfo)" (mouseleave)="onmouseleave($event)" data-toggle="modal">
        <img src="../../../../../assets/images/loginflow/reg_info.png" alt="reg_info" />
      </span></h3> -->
  </div>
  <form [formGroup]="registerForm" (ngSubmit)="onSubmit(registerForm.value)" autocomplete="false">
    <div class="row">
      <div class="col-md-6">
        <div class="omail-form-field">
          <mat-label>First Name <span class="asterick">*</span></mat-label>
          <mat-form-field appearance="outline" class="w-100">
            <input matInput placeholder="Enter First Name" type="text" formControlName="firstName"
              [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" appAlphabetOnly />
            <mat-icon matSuffix>person_outline</mat-icon>
          </mat-form-field>
          <mat-error *ngIf="f.firstName.touched && f.firstName.errors" class="invalid-feedback">
            <div *ngIf="f.firstName.errors.required">First Name is required</div>
            <div *ngIf="f.firstName.errors.minlength">
              First Name must be min 2 characters
            </div>
            <div *ngIf="f.firstName.errors.maxlength">
              First Name allows max 26 characters
            </div>
          </mat-error>
          <!-- <mat-error *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
            <div *ngIf="f.firstName.errors.required">
              First Name is required
            </div>
          </mat-error> -->
        </div>
      </div>
      <div class="col-md-6">
        <div class="omail-form-field">
          <mat-label>Last Name <span class="asterick">*</span></mat-label>
          <mat-form-field appearance="outline" class="w-100">
            <input matInput type="text" placeholder="Enter Last Name" formControlName="lastName"
              [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" appAlphabetOnly />
            <mat-icon matSuffix>person_outline</mat-icon>
          </mat-form-field>
          <mat-error *ngIf="f.lastName.touched && f.lastName.errors" class="invalid-feedback">
            <div *ngIf="f.lastName.errors.required">Last Name is required</div>
            <div *ngIf="f.lastName.errors.minlength">
              Last Name must be min 2 characters
            </div>
            <div *ngIf="f.lastName.errors.maxlength">
              Last Name allows max 26 characters
            </div>
          </mat-error>

        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="omail-form-field">
          <mat-label>User ID <span class="asterick">*</span></mat-label>
          <mat-form-field appearance="outline" class="w-100 userid-suffix">
            <input matInput type="text" placeholder="Enter User ID" formControlName="userName" name="name"
              autocomplete="name" [ngClass]="{ 'is-invalid': submitted && f.userName.errors }" />
            <span matSuffix>{{domainPath}}</span>
          </mat-form-field>
          <mat-error *ngIf="f.userName.touched && f.userName.errors" class="invalid-feedback">
            <div *ngIf="f.userName.errors.required">Username is required</div>
            <div *ngIf="f.userName.errors.minlength">
              User ID must be min 4 characters
            </div>
            <div *ngIf="f.userName.errors.maxlength">
              User ID allows max 32 characters
            </div>
            <!-- <div *ngIf="f.userName.errors.pattern">
              User ID should contain one number and special characters only
            </div> -->
            <div
              *ngIf="!f.userName.errors.minlength && !f.userName.errors.maxlength && f.userName.errors.checkUserName">
              User ID already in use.
            </div>
          </mat-error>
          <!-- <mat-error *ngIf="submitted && f.userName.errors" class="invalid-feedback">
            <div *ngIf="f.userName.errors.required">Username is required</div>
            <div *ngIf="f.userName.errors.minlength">
              Minimum 5 characters should be Username
            </div>
            <div *ngIf="f.userName.errors.maxlength">
              Maximum  20 characters Username is allowed
            </div>
            <div *ngIf="f.userName.errors.checkUserName">
              Username already in use.
            </div>
          </mat-error> -->
        </div>
      </div>
      <div class="col-md-6">
        <div class="omail-form-field">
          <mat-label>Alternate Email Address <span class="asterick">*</span></mat-label>
          <mat-form-field appearance="outline" class="w-100">
            <input matInput placeholder="Enter Alternate Email Address" type="text" formControlName="recoveryEmailId"
              [ngClass]="{ 'is-invalid': submitted && f.recoveryEmailId.errors }" />
            <mat-icon matSuffix>email</mat-icon>
          </mat-form-field>

          <mat-error *ngIf="f.recoveryEmailId.touched  && f.recoveryEmailId.errors" class="invalid-feedback">
            <div *ngIf="f.recoveryEmailId.errors.checkalternateemail &&!f.recoveryEmailId.errors.required && !f.recoveryEmailId.errors.minlength && 
            !f.recoveryEmailId.errors.email && !f.recoveryEmailId.errors.maxlength  ">
              Email already in use.
            </div>
            <div *ngIf="f.recoveryEmailId.errors.required"> Alternate Email Address is required</div>
            <div *ngIf="f.recoveryEmailId.errors.minlength">
              Alternate Email must be min 4 characters
            </div>
            <div *ngIf="f.recoveryEmailId.errors.maxlength">
              Alternate Email allows max 32 characters
            </div>
            <div *ngIf=" f.recoveryEmailId.errors.email &&!f.recoveryEmailId.errors.required &&
           !f.recoveryEmailId.errors.checkalternateemail && !f.recoveryEmailId.errors.minlength && 
           !f.recoveryEmailId.errors.maxlength">Please enter valid Email Id.</div>
          </mat-error>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="password-error-text">
          <mat-label>Password <span class="asterick">*</span></mat-label>
          <mat-form-field appearance="outline" class="w-100">
            <input matInput [type]="hide ? 'password' : 'text'" id="exampleInputPassword1" placeholder="Enter password"
              formControlName="password" />
            <!-- <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button> -->
            <mat-icon matSuffix (click)="hide= !hide" class="cursor-pointer">{{hide ? 'visibility_off' : 'visibility'}}
            </mat-icon>
          </mat-form-field>
          <mat-error *ngIf="f.password.touched && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required"> Password is required</div>
            <div *ngIf="!f.password.errors.required && f.password.errors.maxlength">
              Password allows max 16 characters
            </div>
            <div *ngIf="!f.password.errors.required && f.password.errors.minlength">
              Password must be min 8 characters
            </div>
            <div
              *ngIf="!f.password.errors.required && !f.password.errors.minlength && !f.password.errors.maxlength && f.password.errors.pattern">
              Password doesn't meet the criteria
            </div>
          </mat-error>

        </div>
        <!-- <div class="password-strength-meter">
          <password-strength-meter [password]="password?.value"></password-strength-meter>
        </div> -->
      </div>
      <div class="col-md-6">
        <div class="password-error-text">
          <mat-label>Confirm Password <span class="asterick">*</span></mat-label>
          <mat-form-field appearance="outline" class="w-100">
            <input matInput [type]="hide1 ? 'password' : 'text'" id="exampleInputPassword1"
              placeholder="Enter Confirm Password" formControlName="confirmPassword"
              [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
            <!-- <button mat-icon-button matSuffix (click)="hide1 = !hide1" [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide1">
              <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button> -->
            <mat-icon matSuffix (click)="hide1 = !hide1" class="cursor-pointer">
              {{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
          </mat-form-field>

          <mat-error *ngIf="f.confirmPassword.touched && f.confirmPassword.errors" class="invalid-feedback">
            <div *ngIf="f.confirmPassword.errors.required"> Confirm Password is required</div>
            <div *ngIf="!f.confirmPassword.errors.required && f.confirmPassword.errors.mustMatch">
              Password and confirm password does not match
            </div>
          </mat-error>

        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="omail-form-field">
          <mat-label>Country Name <span class="asterick">*</span></mat-label>
          <div class="reg-country-block">
            <select (change)="getcode($event)" class="custom-select" formControlName="countryId"
              placeholder="Select Country">
              <option value="" selected>Select Country</option>
              <option *ngFor="let country of countryList; let i = index;" [value]="country.countryId">
                {{country.countryName}}</option>
            </select>
            <mat-error *ngIf="f.countryId.touched && f.countryId.errors" class="invalid-feedback">
              <div *ngIf="f.countryId.errors.required"> Country is required</div>
            </mat-error>
          </div>
        </div>
        <!-- <mat-form-field appearance="fill" appearance="outline" class="w-100">
          <mat-select formControlName="countryId" placeholder="Select Country">
            <mat-option *ngFor="let country of countryList; let i = index;" [value]="country.countryId">
              {{country.countryName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error *ngIf="f.countryId.touched && f.countryId.errors" class="invalid-feedback">
          <div *ngIf="f.countryId.errors.required"> Country is required</div>          
      </mat-error> -->
      </div>
      <div class="col-md-4">
        <!-- <mat-label>Region Code</mat-label>
        <mat-form-field appearance="outline" class="w-100">
          <input matInput placeholder="Region Code" type="text" appAlphabetOnly />
        </mat-form-field> -->
        <div class="form-group">
          <mat-label>Country Code <span class="asterick">*</span></mat-label>
          <div class="reg-country-block">
            <select class="custom-select" formControlName="callCode">
              <option value="">Region Code</option>
              <!-- <option *ngFor="let item of countryZoneList; let i =index" [value]="item.callcode" selected>
                {{item.callcode}}</option> -->
              <option *ngFor="let item of countryZoneList" [ngValue]="item.callcode"
                [selected]="item.callcode===selectedCallcode">
                {{item.callcode}}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="omail-form-field">
          <mat-label>Phone Number <span class="asterick">*</span></mat-label>
          <mat-form-field appearance="outline" class="w-100">
            <input matInput type="text" #input OnlyNumber="true" formControlName="contactNumber"
              placeholder="Mobile Number" [ngClass]="{ 'is-invalid': submitted && f.contactNumber.errors }" />
            <mat-icon matSuffix>phone_android</mat-icon>
          </mat-form-field>
          <mat-error *ngIf="f.contactNumber.touched && f.contactNumber.errors" class="invalid-feedback">
            <div *ngIf="f.contactNumber.errors.required">
              Mobile Number is required
            </div>
            <div *ngIf="f.contactNumber.errors.pattern">
              Please enter only digits
            </div>
          </mat-error>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="mwotp-block">
          <div>
            <input type="radio" name="radioStatus" value="memorableword" formControlName="radioStatus" />
            <label>Memorable Word</label>
          </div>
          <div>
            <input type="radio" name="radioStatus" value="otp" formControlName="radioStatus" />
            <label for="OTP">Opt-in for OTP</label>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="registerForm?.get('radioStatus')?.value === 'memorableword'">
      <div class="col-md-6">
        <div class="omail-form-field">
          <mat-label>Memorable Word <span class="asterick">*</span></mat-label>
          <mat-form-field appearance="outline" class="w-100">
            <input matInput type="text" #input formControlName="memorableWord" placeholder="Enter Memorable Word"
              (keydown.space)="$event.preventDefault()"
              [ngClass]="{ 'is-invalid':(submitted || f.memorableWord.touched) && f.memorableWord.errors }" />
            <mat-icon matSuffix>person</mat-icon>
          </mat-form-field>
          <mat-error *ngIf="(submitted  || f.memorableWord.touched) && f.memorableWord.errors" class="invalid-feedback">
            <div *ngIf="f.memorableWord.errors.required">
              Memorable Word is Required
            </div>
            <div *ngIf="f.memorableWord.errors.minlength">Memorable Word must be min 8 characters</div>
            <div *ngIf="f.memorableWord.errors.maxlength">Memorable Word allows max 20 characters</div>
            <div
              *ngIf="!f.memorableWord.errors.maxlength && !f.memorableWord.errors.minlength && f.memorableWord.errors.pattern">
              Memorable Word allows only Alphabetic Letters</div>
          </mat-error>
        </div>
      </div>
      <div class="col-md-6">
        <div class="omail-form-field">
          <mat-label>Hint for Memorable Word <span class="asterick">*</span></mat-label>
          <mat-form-field appearance="outline" class="w-100">
            <input matInput type="text" #input OnlyNumber="true" formControlName="hintfroMemorableWord"
              (keydown.space)="$event.preventDefault()" placeholder="Enter Hint for Memorable Word"
              [ngClass]="{ 'is-invalid':(submitted || f.hintfroMemorableWord.touched) && f.hintfroMemorableWord.errors }" />
            <mat-icon matSuffix>person</mat-icon>
          </mat-form-field>
          <mat-error *ngIf="(submitted  || f.hintfroMemorableWord.touched) && f.hintfroMemorableWord.errors"
            class="invalid-feedback">
            <div *ngIf="f.hintfroMemorableWord.errors.required">
              Memorable-Hint is Required
            </div>
            <div *ngIf="f.hintfroMemorableWord.errors.minlength">Hint for Memorable Word must be min 8 characters</div>
            <div *ngIf="f.hintfroMemorableWord.errors.maxlength">Hint for Memorable Word allows max 20 characters</div>
            <div
              *ngIf="!f.hintfroMemorableWord.errors.minlength && !f.hintfroMemorableWord.errors.maxlength && f.hintfroMemorableWord.errors.pattern">
              Hint for Memorable Word allows only Alphabetic Letters</div>
          </mat-error>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 offset-3">
        <!-- <div class="terms-check-text">`
          <input type="checkbox" formControlName="acceptTerms" id="acceptTerms" class="form-check-input"
            [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }" />
          <label class="form-check-label" for="flexCheckChecked">
            By Clicking, you agree to our
            <span><a href="/terms-and-conditions" target="_blank">terms and conditions</a></span>
            cookie policy, you may receive email notification from us and
            can option out at any time.
          </label>
        </div> -->
        <div class="form-btn">
          <button type="submit" class="btn-block" [disabled]="!registerForm.valid">Register</button>
        </div>
      </div>
    </div>
  </form>
  <div class="redirect-link">
    Have you an account? <a routerLink="/sign-in">Login</a>
  </div>
</div>
<!-- {{registerForm.value | json}} -->

<!--Register Form rules Modal Start -->
<ng-template #reginfo let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">O-Mail Registration Requirement</h4>
    <button type="button" class="btn btn-link btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <img src="../../../../../assets/images/loginflow/reg-info-close-icon.png" alt="close-icon" />
    </button>
  </div>
  <div class="modal-body">
    <ol>
      <li>First and last name: <strong> Max 26 and min 2 alpha</strong> </li>
      <li>User ID: <strong> Max 32 and min 4 alphanumeric and special characters</strong></li>
      <li>Alternative Mail ID: <strong> Max 32 and min 4 alphanumeric</strong></li>
      <li> <strong><span>Password: </span> Max 16 characters and min 8 characters <span>(8 characters with at least one
            upper case,
            lowercase, number and special character)</span></strong> </li>
      <li>Country name: <strong> Select one country</strong></li>
      <li>Phone number: <strong> Numeric</strong></li>
    </ol>
  </div>
</ng-template>
<!--Register Form rules Modal End -->