import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as SettingsActions from './settings.actions';
import { SettingsService } from 'src/app/core/http/settings.service';

@Injectable()
export class SettingsEffects {
  loadSettingss$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SettingsActions.getUser),
      concatMap(() =>
        /** An EMPTY observable only emits completion. Replace with your own observable API request */
        this.settingsService.userDetails().pipe(
          map((user) => {
            // console.log('user details');
            return SettingsActions.getUserSuccess({ user });
          }),
          catchError((error) => {
            return of(SettingsActions.getUserFailure({ error }));
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private settingsService: SettingsService
  ) {}
}
