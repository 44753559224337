// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  //apiUrl: 'https://omailapi.ofounders.net/',
  // apiUrl: 'https://omailapidev.onpassive.com/',
  //apiUrl: 'https://mailboxapiqa.onpassive.com/',
  // apiUrl: 'http://192.168.5.252:2029/', // valli
  // apiUrl: 'http://192.168.6.237:2029/', //ka,esh
  // apiUrl: 'http://192.168.5.63:2028/', //kamesh
  // apiUrl: 'http://192.168.3.11:2028/', //venkat
  // apiUrl: 'http://192.168.1.66:2029/',
  // apiUrl: 'http://192.168.6.59:2029/', // praveen
  // apiUrl: 'http://192.168.137.1:8090/', // Suresh G


  // apiUrl: 'https://qa.o-mails.net:8443/omailqa/',
  // domainPath: '@qa.o-mails.net',
  apiUrl: 'https://mailbox-api.onfusiondemo.cc/',
  // apiUrl: 'http://192.168.3.132:8080/omailqa/',
  domainPath: '@onfusiondemo.cc',
  // domainPath: '@qa.omail.com',
  omail_cloudFrontUrl: 'https://d3gawnbnjw4x53.cloudfront.net/',
  accessKey: ')Npassive@2021',
  // accessKey:'ONPASSIVE@)@)!@#',
  isCkeditor: false,
  production: false,
  froalaKey: "mPD4tD2G2F1H1H1C3B1lFa1f1PVWEd2Fa1XHTHh1THMMb1NCg1tA2B2C2E1C5F1G2F1H4C10==",
  aimlSmartReply: 'https://smartreply-dev.onpassive.ac/',
  excelType: ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","application/vnd.ms-excel",],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
