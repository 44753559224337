<div class="sign-fm">
  <div class="logo">
    <a routerLink="/" class="logo"><img src="/assets/images/fm-logo.svg" routerLink="/" alt="O-Mail" /></a>
  </div>
  <div class="form-header">
    <h3 *ngIf="recoveryDetails?.memorableWord">Forgot Login and Memorable Word  Details <span class="reg-info" (click)="memOPen(mempwdInfo)"><img
      src="../../../../../assets/images/loginflow/reg_info.png"  alt="password_info" /></span></h3>
    <h3 *ngIf="recoveryDetails?.password && !recoveryDetails?.memorableWord">Reset Password <span class="reg-info" (click)="open(pwdInfo)"><img
      src="../../../../../assets/images/loginflow/reg_info.png"  alt="password_info" /></span></h3>
    <!-- <h3>Forgot Memorable Word</h3> -->
    <!-- <h3>Forgot Login Details</h3> -->
  </div>
  <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
    <!-- -->
   <div>
    <div class="row">
      <div class="col-md-12">
        <div class="omail-form-field">
          <mat-label>User Email ID<span class="asterick">*</span></mat-label>
          <mat-form-field appearance="outline" class="w-100">
            <input matInput  type="email" formControlName="emailId" readonly >
            <mat-icon matSuffix>person_outline</mat-icon>
          </mat-form-field>
          <!-- <mat-error *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">
              User ID is required
            </div>
          </mat-error> -->
        </div>
      </div>
    </div>
  </div>
    <!-- -->
    <!--Reset Password -->
    <div *ngIf="recoveryDetails?.password">
      <div class="row">
        <div class="col-md-12">
          <div class="omail-form-field">
            <mat-label>Password<span class="asterick">*</span></mat-label>
            <mat-form-field appearance="outline" class="w-100">
              <!-- <input matInput type="password" [type]="showpassword ? 'text' :'password' "
              placeholder="Enter Password" formControlName="password">
              <a href="javascript:void(0);" class="bg-transparent border-0 input-group-text password_eye" (keyup.Space)="onTabKey()">  
                <img *ngIf="!showpassword" (click)="showhidePassword(true)" src="{{'../../../'+assetIcon+'eye-close.svg'}}"  alt="" class="img-fluid">
                      <img *ngIf="showpassword" (click)="showhidePassword(false)" src="{{'../../../'+assetIcon+'eye_open.svg'}}"  class="img-fluid" alt="">
                    </a> -->
              <input matInput  [type]="hide ? 'password' : 'text'" placeholder="Enter Password"
                formControlName="password"  [ngClass]="{ 'is-invalid':(submitted || f.password.touched) && f.password.errors }" required /> 

              <mat-icon matSuffix (click)="hide = !hide" class="cursor-pointer">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </mat-form-field>
            <mat-error *ngIf="(submitted || f.password.touched) && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required"> Password is required</div>
              <div *ngIf="!f.password.errors.required && f.password.errors.maxlength">
                Password allows max 16 characters
              </div>
              <div *ngIf="!f.password.errors.required && f.password.errors.minlength">
                Password must be min 8 characters
              </div>
              <div *ngIf="!f.password.errors.required && !f.password.errors.minlength && !f.password.errors.maxlength && f.password.errors.pattern">
                 Password doesn't meet the acceptance criteria
              </div>
          </mat-error>
            <!-- <mat-error *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">Password is required</div>
            </mat-error> -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="omail-form-field">
            <mat-label>Confirm Password<span class="asterick">*</span></mat-label>
            <mat-form-field appearance="outline" class="w-100">
              <input matInput [type]="hide1 ? 'password' : 'text'" placeholder="Enter Confirm Password"
                formControlName="confirmPassword" required />
              <!-- <button mat-icon-button matSuffix (click)="hide1 = !hide1" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide1">
                <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button> -->
              <mat-icon matSuffix (click)="hide1 = !hide1" class="cursor-pointer">{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
            </mat-form-field>
            <mat-error *ngIf="(submitted || f.confirmPassword.touched) && f.confirmPassword.errors" class="invalid-feedback">
              <div *ngIf="f.confirmPassword.errors.required"> Confirm Password is required</div>
              <div *ngIf="!f.confirmPassword.errors.required && f.confirmPassword.errors.mustMatch">
               Password and Confirm Password does not match
              </div>
          </mat-error>
            <!-- <mat-error *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
              <div *ngIf="f.confirmPassword.errors.required">Password is required</div>
            </mat-error> -->
          </div>
        </div>
      </div>
    </div>
    <!-- -->
    <!--Forgot Memorable Word -->
    <div *ngIf="recoveryDetails?.memorableWord">
      <div class="row">
        <div class="col-md-12">
          <div class="omail-form-field">
            <mat-label>Memorable Word <span class="asterick">*</span></mat-label>
            <mat-form-field appearance="outline" class="w-100">
              <input matInput type="text" #input formControlName="memorableWord" placeholder="Enter Memorable Word" (keydown.space)="$event.preventDefault()"
              [ngClass]="{ 'is-invalid':(submitted || f.memorableWord.touched) && f.memorableWord.errors }" />
              <mat-icon matSuffix>person</mat-icon>
            </mat-form-field>
            <!-- [ngClass]="{ 'is-invalid': submitted && f.MemorableWord.errors }" <mat-error *ngIf="submitted && f.MemorableWord.errors" class="invalid-feedback">
              <div *ngIf="f.MemorableWord.errors.required">
                Memorable Word is Required
              </div>
            </mat-error> -->
            <mat-error *ngIf="(submitted ||f.memorableWord.touched) && f.memorableWord.errors" class="invalid-feedback">
              <div *ngIf="f.memorableWord.errors.required && !f.memorableWord.errors.pattern"> Memorable Word is required</div>
              <div *ngIf="!f.memorableWord.errors.required &&  f.memorableWord.errors.maxlength">
                <!-- !f.memorableWord.errors.pattern && -->
                Memorable Word allows max 20 characters
              </div>
              <div *ngIf="f.memorableWord.errors.minlength">
                Memorable Word must be min 8 characters
              </div>
             
              <div *ngIf="!f.memorableWord.errors.required && !f.memorableWord.errors.minlength && f.memorableWord.errors.pattern && !f.memorableWord.errors.maxlength">Memorable Word allows only Alphabetic Letters</div>
          </mat-error>
          </div>
        </div>
        <div class="col-md-12">
          <div class="omail-form-field">
            <mat-label>Confirm Memorable Word<span class="asterick">*</span></mat-label>
            <mat-form-field appearance="outline" class="w-100">
              <input matInput type="text" #input formControlName="conmemorableWord" placeholder="Enter Confirm Memorable Word"
              [ngClass]="{ 'is-invalid':(submitted || f.conmemorableWord.touched) && f.conmemorableWord.errors }" />
              <mat-icon matSuffix>person</mat-icon>
            </mat-form-field>
            <!-- [ngClass]="{ 'is-invalid': submitted && f.MemorableWord.errors }"<mat-error *ngIf="submitted && f.MemorableWord.errors" class="invalid-feedback">
              <div *ngIf="f.MemorableWord.errors.required">
                Memorable Word is Required
              </div>
            </mat-error> -->
            <mat-error *ngIf="(submitted || f.conmemorableWord.touched) && f.conmemorableWord.errors" class="invalid-feedback">
              <div *ngIf="f.conmemorableWord.errors.required"> Confirm Memorable Word is required</div>
              <div *ngIf="f.conmemorableWord.errors.mustMatch">
                Memorable and Confirm Memorable Words doesn't match
               </div>
          </mat-error>
          </div>
        </div>
        <div class="col-md-12">
          <div class="omail-form-field">
            <mat-label>Hint for Memorable Word <span class="asterick">*</span></mat-label>
            <mat-form-field appearance="outline" class="w-100">
              <input matInput type="text" #input OnlyNumber="true" formControlName="hintWord" placeholder="Enter Hint for Memorable Word" 
                [ngClass]="{ 'is-invalid':(submitted || f.hintWord.touched) && f.hintWord.errors }"
               />
              <mat-icon matSuffix>person</mat-icon>
            </mat-form-field>
            <mat-error *ngIf="(submitted || f.hintWord.touched) && f.hintWord.errors"  class="invalid-feedback">
              <div *ngIf="f.hintWord.errors.required"> Hint for Memorable Word is required</div>
              <div *ngIf="f.hintWord.errors.minlength">Hint for Memorable Word must be min 8 characters</div>
              <div *ngIf="f.hintWord.errors.maxlength">Hint for Memorable Word allows max 20 characters</div>
              <div *ngIf="!f.hintWord.errors.maxlength && !f.hintWord.errors.minlength && f.hintWord.errors.pattern">Hint for Memorable Word allows only Alphabetic Letters</div>
            </mat-error>  
            <!--   [ngClass]="{ 'is-invalid': submitted && f.MemorableHint.errors }"<mat-error *ngIf="submitted && f.MemorableHint.errors" class="invalid-feedback">
              <div *ngIf="f.MemorableHint.errors.required">
                Memorable-Hint Required
              </div>
            </mat-error> -->
          </div>
        </div>
      </div>
    
    </div>
    <div class="form-btn">
      <button type="submit" class="btn-block" >Submit </button>
    </div>
    <!-- -->

   
  </form>
  <div class="redirect-link">
    Have you an account? <a routerLink="/sign-in">Login</a>
  </div>
  <div class="redirect-link">
    Don’t have an account? <a routerLink="/sign-up">Register</a>
  </div>
</div>
<ng-template #pwdInfo let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">O-Mail Password Requirement</h4>
    <button type="button" class="btn btn-link btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <img src="../../../../../assets/images/loginflow/reg-info-close-icon.png" alt="close-icon" />
    </button>
  </div>
  <div class="modal-body">
    <ol>
      <li> <strong><span>Password: </span> Max 16 characters and min 8 characters <span>(8 characters with at least one upper case,
            lowercase, number and special character)</span></strong> </li>
    </ol>
  </div>
</ng-template>

<ng-template #mempwdInfo let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">O-Mail Forgot Login dcetails Requirement</h4>
    <button type="button" class="btn btn-link btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <img src="../../../../../assets/images/loginflow/reg-info-close-icon.png" alt="close-icon" />
    </button>
  </div>
  <div class="modal-body">
    <ol>
      <li> <strong><span>Password: </span> Max 16 characters and Min 8 characters <span>(8 characters with at least one upper case,
            lowercase, number and special character)</span></strong> </li>
        <li>Memorable word : It must be 8 to 20 characters long.</li>
        <li>Memorable word :It should not include any special characters like hyphens or blank spaces.</li>
    </ol>
  </div>
</ng-template>