<div class="sign-fm">
  <div class="logo">
    <a routerLink="/" class="logo"><img src="/assets/images/fm-logo.svg" routerLink="/" alt="O-Mail" /></a>
  </div>
  <!-- Login form start -->
  <div *ngIf='logingotm'>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" autocomplete="off">
      <div class="form-header">
        <h3>Login</h3>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="omail-form-field">
            <mat-label>User ID<span class="asterick">*</span></mat-label>
            <mat-form-field appearance="outline" class="w-100">
              <input matInput placeholder="Enter User ID" type="email" formControlName="email" required>
              <mat-icon matSuffix>person_outline</mat-icon>
            </mat-form-field>
            
            <mat-error *ngIf="(submitted ||f.email.touched) && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">
                User ID is Required.
              </div>
              <div *ngIf="f.email.errors.email">
                <!-- <div *ngIf="f.email.errors.email && !f.email.errors.minlength && !f.email.errors.maxlength"> -->
                  Enter Valid User ID.
                </div>
              <!-- <div *ngIf="f.email.errors.minlength">
                User ID should be 6 characters
              </div>
              <div *ngIf="f.email.errors.maxlength">
                User ID allows max 30 characters
              </div> -->
              <!-- <div *ngIf="f.email.errors.minlength ">
                User ID Should be atleast 4 characters
              </div> -->
              <!-- <div *ngIf="f.email.errors.maxlength ">
                User ID Should be   max 32 characters
              </div> -->
            </mat-error>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="omail-form-field">
            <mat-label>Password<span class="asterick">*</span></mat-label>
            <mat-form-field appearance="outline" class="w-100">
              <input matInput placeholder="Enter password" [type]="hide ? 'password' : 'text'"
                formControlName="password" required />
              <!-- <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button> -->
              <mat-icon matSuffix (click)="hide = !hide" class="cursor-pointer">
                {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </mat-form-field>
            <mat-error *ngIf="(submitted || f.password.touched) && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">
                Password is Required.
              </div>
              <div *ngIf="loginForm.value.password && !f.password.errors.required">
                Enter Valid Password.
              </div>
              <!-- *ngIf="loginForm.get('password')?.invalid && (loginForm.get('password')?.touched ||
             loginForm.get('password')?.dirty)"  -->
              <!-- <div  *ngIf="loginForm.get('password')?.errors('required')">Password is required</div> -->
              <!-- <div *ngIf="loginForm.value.password && !f.password.errors.required">Password must be a combination of lower-case, upper-case, numbers and at least 9 characters long</div> -->
              <!-- <div *ngIf="loginForm.get('password')?.errors('passwordStrength')">
                {{loginForm.get('password')?.errors['passwordStrength']}}
              </div> -->
              <!-- <div > {{passwordattamts | json}}</div> -->
            </mat-error>
            <div class="reserror"> {{passwordattamts}}</div>
          </div>
        </div>
      </div>
      <div class="remember-forgotlink-block">
        <div class="remember-text">
          <mat-checkbox>Remember me</mat-checkbox>
        </div>
        <div class="forgot-text">
          <a routerLink="/forgot-password">Forgot Login Details</a>
        </div>
      </div>
      <div class="form-btn">
        <button [disabled]="loading$ | async" type="submit" class="btn-block">Login</button>
      </div>
    </form>
    <div class="redirect-link">
      Don’t have an account? <a routerLink="/sign-up">Register</a>
    </div>
    <div class="terms-policy-links-text">
      <a routerLink="/terms-and-conditions" target="_blank" class="sigup-btn">Terms And Conditions</a>
      <a routerLink="/privacy-policy" target="_blank" class="sigup-btn float-right">Privacy Policy</a>
    </div>
  </div>
  <!-- Login form End -->
  <!-- Otp and Memorable Word start -->
  <div>
    <form (ngSubmit)="otpSubmit()" [formGroup]="form">
      <div *ngIf=otpform>
        <div class="form-header">
          <h3>Enter the OTP</h3>
        </div>
        <div class="otpmw-feilds">
          <input *ngFor="let input of formInputOtp;  index as i" #formRow type="text" formControlName="{{input}}"
            maxlength="1" (keyup)="keyUpEvent($event, i)" (keypress)="numberOnly($event)">
        </div>
        <div class="otp-time-block">
         
          <div class="redirect-link">

            Don’t received OTP? <span class="resendotp-text" (click)='ResendOtp()'
            [class.resendtext]="(countDown$ | async) != 0" >Resend OTP</span>
          </div>
          <div class="timeout-text">{{ display }}</div>
          <!-- <div class="d-flex otp_label">
          <p>{{ display }}</p>          
          <p *ngIf="showNotice">{{ notice }}</p>
        </div> -->
          <!-- {{display}} -->
          <!-- <div *ngIf="(countDown$ | async) === 0">
            Don’t received OTP? <span (click)='ResendOtp()'> Resend OTP </span>
          </div> -->
          <!-- <div class="timeout-text"  *ngIf="(countDown$ | async) === 0">
          {{ countDown$ | async }}:Sec
        </div> -->
          <!-- <div class="timeout-text" >
          {{ countDown$ | async }}:Sec
        </div> -->
        </div>
        <div class="form-btn">
          <button type="submit" [disabled]="form.invalid" class="btn-block">Login</button>
        </div>
        <div class="redirect-link">
          Don’t have an account? <a routerLink="/sign-up">Register</a>
        </div>
        <div class="terms-policy-links-text">
          <a routerLink="/terms-and-conditions" target="_blank" class="sigup-btn">Terms And Conditions</a>
          <a routerLink="/privacy-policy" target="_blank" class="sigup-btn float-right">Privacy Policy</a>
        </div>
      </div>
      <div *ngIf=memorabale>
        <div class="form-header">
          <h3>Login</h3>
        </div>
        <div>
          <h5>Enter the following characters to login</h5>
        </div>
        <div>
          <ul class="mw-feilds">
            <li *ngFor="let input of formInput;  index as i">
              <h6>{{input}}<sup>{{input === '1' ? 'st' :input === '2' ? 'nd':input === '3' ? 'rd':'th'}}</sup></h6>
              <input #formRow type="text" formControlName="{{input}}" maxlength="1" (keyup)="keyUpEvent($event, i)">
            </li>
          </ul>
        </div>
        <div class="form-btn">
          <button type="submit" [disabled]="form.invalid" class="btn-block">Login</button>
        </div>
        <div class="redirect-link">
          Don’t have an account? <a routerLink="/sign-up">Register</a>
        </div>
        <div class="terms-policy-links-text">
          <a routerLink="/terms-and-conditions" target="_blank" class="sigup-btn">Terms And Conditions</a>
          <a routerLink="/privacy-policy" target="_blank" class="sigup-btn float-right">Privacy Policy</a>
        </div>
      </div>
    </form>
    <!-- Otp and Memorable Word End -->
  </div>
</div>
