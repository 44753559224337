import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-auth-layout-one',
  templateUrl: './auth-layout-one.component.html',
  styleUrls: ['./auth-layout-one.component.scss']
})
export class AuthLayoutOneComponent implements OnInit {
  @ViewChild("formAnim") formAnim?: ElementRef;
  constructor(private rederer: Renderer2) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.rederer.addClass(this.formAnim?.nativeElement, "active");
    }, 3000);
  }

}
