import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/app/core/http/auth.service';
import { AppState } from 'src/app/store';
import { login } from 'src/app/store/auth/auth.actions';
import { isLoading } from 'src/app/store/loader/loader.actions';

@Component({
  selector: 'app-auto-login',
  templateUrl: './auto-login.component.html',
  styleUrls: ['./auto-login.component.scss'],
})
export class AutoLoginComponent implements OnInit {
  constructor(
    private store: Store<AppState>,
    private activateRoute: ActivatedRoute,
    private authService: AuthService
  ) {
    const loginId = this.activateRoute.snapshot.params.loginId;
    // console.log(loginId);
    this.store.dispatch(isLoading({ isLoading: true }));

    // const email = 'developer';
    // const password = 'Develop@123';
    // this.store.dispatch(login({ email, password }));
    this.authService.getUserById(loginId).subscribe((res) => {
      const email = res.userName;
      const password = res.password;
      // this.store.dispatch(login({ email, password }));
    });
  }

  ngOnInit(): void {}
}
