import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLayoutOneComponent } from 'src/app/shared/layouts/auth-layout-one/auth-layout-one.component';
import { AuthLayoutComponent } from 'src/app/shared/layouts/auth-layout/auth-layout.component';
import { BaseLayoutComponent } from 'src/app/shared/layouts/base-layout/base-layout.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { AutoLoginComponent } from './components/auto-login/auto-login.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { FeatureComponent } from './components/feature/feature.component';
import { WhyOmailComponent } from './components/why-omail/why-omail.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { MemorbleWordComponent } from './components/memorble-word/memorble-word.component';
import { OtpComponent } from './components/otp/otp.component';

if (window.location.href.includes('sign-in/')) {
  sessionStorage.setItem("verifyLink", window.location.href.slice(window.location.href.indexOf('sign-in') + 8));
} else {
  sessionStorage.setItem("verifyLink",'');

}
if (window.location.href.includes('recoverypassword/')) {
  sessionStorage.setItem("resetLink", window.location.href.slice(window.location.href.indexOf('recoverypassword') + 17));
} else {
  sessionStorage.setItem("resetLink",'');

}

const routes: Routes = [
  {
    path: '', component: BaseLayoutComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'feature', component: FeatureComponent },
      { path: 'why-omail', component: WhyOmailComponent },
      { path: 'about-us', component: AboutUsComponent },
      { path: 'contact-us', component: ContactUsComponent },
      { path: 'faqs', component: FaqsComponent },
      { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
      { path: 'privacy-policy', component: PrivacyPolicyComponent },
    ],
  },
  {
    path: '', component: AuthLayoutComponent,
    
    children: [
      { path: 'sign-in', component: LoginComponent },
      { path: `sign-in/${decodeURIComponent(window.location.href.slice(window.location.href.indexOf('sign-in') + 8).split('==')[0])}`, redirectTo: 'sign-in' },
      { path: 'otp', component: OtpComponent },
      { path: 'memorable-word', component: MemorbleWordComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'recoverypassword', component: ResetPasswordComponent },
      { path: `recoverypassword/${decodeURIComponent(window.location.href.substring(window.location.href.lastIndexOf('recoverypassword')+17).split('==')[0])}`,  redirectTo:'recoverypassword' },
    
    ],
  },
  
  {
    path: '', component: AuthLayoutOneComponent,
    children: [
      { path: 'sign-up', component: SignUpComponent },
    ],
  },
  { path: 'autologin/:loginId', component: AutoLoginComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {
  
 }
