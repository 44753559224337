import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import {
  NavigationActionTiming,
  StoreRouterConnectingModule
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CarouselModule } from 'ngx-owl-carousel-2';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpErrorInterceptor } from './core/interceptors/http-error.interceptor';
import { HttpInterceptorInterceptor } from './core/interceptors/http-interceptor.interceptor';
import { ComposedataService } from './core/services/composedata.service';
import { AuthModule } from './modules/auth/auth.module';
import { CustompipePipe } from './pipe/custompipe.pipe';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { AngularMaterialModule } from './shared/modules/angular-material.module';
import { metaReducers, reducers } from './store';
import { AppRouteSerializer } from './store/app-route-serializer';
import { SnackBarEffects } from './store/snack-bar/snack-bar.effects';
import { SyncStateEffects } from './store/sync-state/sync-state.effects';

// import { ContactPopupComponent } from './modules/dashboard/components/contact-popup/contact-popup.component';

// import { CustompipePipe } from './pipe/custompipe.pipe';
// import { CustompipePipe } from './custompipe.pipe';

@NgModule({
  declarations: [AppComponent, NotFoundComponent, LoaderComponent, CustompipePipe],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    HttpClientModule,
    AuthModule,
    CarouselModule,
    AppRoutingModule,
    StoreDevtoolsModule.instrument({
      logOnly: environment.production,
    }),
    JwtModule.forRoot({
      config: { throwNoTokenError: true, skipWhenExpired: true },
    }),
    EffectsModule.forRoot([SnackBarEffects, SyncStateEffects]),
    StoreRouterConnectingModule.forRoot({
      serializer: AppRouteSerializer,
      navigationActionTiming: NavigationActionTiming.PostActivation,
    }),
    StoreModule.forRoot(reducers, { metaReducers }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    NgbModule,
  ],
  providers: [
    ComposedataService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
