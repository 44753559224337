import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss']
})
export class HomeHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  @ViewChild("myNameElem") myNameElem: ElementRef | any;
  openNav() {
    this.myNameElem.nativeElement.style.width = "250px";
  }

  closeNav() {
    this.myNameElem.nativeElement.style.width = "0";
  }
}
