import { Injectable } from '@angular/core';
import { AsyncValidator, FormControl } from '@angular/forms';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from 'src/app/core/http/auth.service';
import { CommonApiService } from 'src/app/core/http/common.service';
import { checkUserNames, POST } from 'src/app/core/http/constants';
import { CommonService } from 'src/app/core/services/common.service';

@Injectable({
  providedIn: 'root',
})
export class CheckUserName implements AsyncValidator {
  public encryptInfo;
  public decryptedInfo;
  encrypt: any;
 validate = (control: FormControl) => {
  const { value } = control;
  let params = {}
  params['name'] = value
  console.log(params);

   this.encryptInfo = this.security.encrypt(params);
//   this._commonServ.commonMethod(checkUserNames, this.encryptInfo, POST).subscribe({
//     next:(value)=>{
//       this.encryptInfo = this.security.encrypt(value);
//       console.log(this.encryptInfo);
//       if(value.message === "SUCESS"){
//         // return { checkUserNames:true};
//         console.log(value);
//     //  value= this.encryptInfo
//       }
//       else{
       
//       }
//     },
 
//   error:(err)=>{
//   console.log(err);
//   }
// })
    
   
    return this._commonServ.commonMethod(checkUserNames,this.encryptInfo,POST).pipe(
      
      map((params) => {
        if (params.message === 'EXISTS') {
          return { checkUserName: true };
        } else {
          return null;
        }
      }),
      catchError((err) => {
        // console.log(err)
        return of({ checkUserName: true });
      })
  );
  };
  constructor(private authService: AuthService,private _commonServ: CommonApiService,private security: CommonService,) {}
}


  // return this.authService.checkUserName(value).pipe(
  //   map((value) => {
  //     if (value.message === 'SUCCESS') {
  //       return { checkUserName: true };
  //     } else {
  //       return null;
  //     }
  //   }),
  //   catchError((err) => 
  //     // console.log(err)
  //     return of({ checkUserName: true });
  //   })
  // );