import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { signupParams } from 'src/app/shared/models/signup.model';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { th } from 'date-fns/locale';
import { CommonService } from '../services/common.service';
import { CommonApiService } from './common.service';
import { checkUserNames, POST } from './constants';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // [x: string]: any;
  key!: any;

  public encryptInfo;
  public decryptedInfo;
  encrypt: any;

  constructor(private http: HttpClient, private security: CommonService,private _ComnonSERV:CommonApiService) {
    this.key = environment.accessKey;

  }
  // signup({
  //   contactNumber,
  //   dateOfBirth,
  //   firstName,
  //   lastName,
  //   password,
  //   userName,
  // }: signupRequest) {
  //   return this.http.post(`${environment.apiUrl}/createUser`, {
  //     contactNumber,
  //     dateOfBirth,
  //     firstName,
  //     lastName,
  //     password,
  //     userName,
  //   });
  // }

  signup(payload: any) {
    this.encryptInfo = this.security.encrypt(payload)
    return this.http.post<any>(`${environment.apiUrl}createOmailAiUsers`, this.encryptInfo);
    // return this.http.post<any>(`${environment.apiUrl}createOmailAiUsers`, payload);
  }

  expiredotp(payload) {
    return this.http.post<any>(`${environment.apiUrl}expiredotp`, payload);
  }
  login({ email, password, isMemorable, isOtp, length }: loginRequest) {
    // Use this for encryption
    let parmspayload = {}
    parmspayload['userName'] = email,
      parmspayload['password'] = password,
      parmspayload['isMemorable'] = isMemorable,
      parmspayload['isOtp'] = isOtp,
      parmspayload['length'] = length,
      
      this.encryptInfo = this.security.encrypt(parmspayload)
    //With encoder UI Component
    // this.encryptInfo = encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify({
    //   userName: email,
    //   password,
    //   isMemorable,isOtp,length
    // }), this.key).toString());

    return this.http.post<any>(`${environment.apiUrl}validateEmail`, this.encryptInfo);
    // return this.http.post<any>(`${environment.apiUrl}validateEmail`, {
    //   userName: email,
    //   password,
    //   isMemorable,isOtp,length
    // });
  }

  autologin({ userName, encrypted }: autoLoginRequest) {
    let loginpayload = {}
    loginpayload['userName'] = userName,
    loginpayload['encrypted'] = encrypted,
    this.encryptInfo = this.security.encrypt(loginpayload)
    return this.http.post<any>(`${environment.apiUrl}autoLogin`, this.encryptInfo);
    // return this.http.post<loginResponse>(`${environment.apiUrl}autoLogin`, {
    //   userName: userName,
    //   encrypted: encrypted,
    // });
  }
  
  validateEmail({ email, isMemorable, otp, keys, length, values, role }: emailvalidateRequest) {

    let loginpayload = {}
    loginpayload['email'] = email,
      loginpayload['otp'] = otp,
      loginpayload['isMemorable'] = isMemorable,
      loginpayload['keys'] = keys,
      loginpayload['values'] = values,
      loginpayload['values'] = values,
      loginpayload['role'] = role,

      this.encryptInfo = this.security.encrypt(loginpayload)
    return this.http.post<any>(`${environment.apiUrl}login`, this.encryptInfo);
    // return this.http.post<loginResponse>(`${environment.apiUrl}login`, {
    //   email: email,
    //   otp: otp,
    //   isMemorable: isMemorable,
    //   keys: keys,
    //   length: length,
    //   values: values,
    // }), this.key).toString();

    // console.log(this.encryptInfo);


  }

  forgotPassword(payload: string) {

    this.encryptInfo = this.security.encrypt(payload)
    return this.http.post<any>(`${environment.apiUrl}forgotDetails`, this.encryptInfo);
    // return this.http.post<any>(`${environment.apiUrl}forgotDetails`, payload
    // );
  }
  resetPassword(payload: any) {
    this.encryptInfo = this.security.encrypt(payload)
    return this.http.post<any>(
      `${environment.apiUrl}recoveryPassword`,
      this.encryptInfo
    );
  }

  setPassword(payload: string) {
    this.encryptInfo = this.security.encrypt(payload)
    return this.http.post<any>(`${environment.apiUrl}updateDetails`, this.encryptInfo
    );
  }

  getUserDetailsByRecoverId(recoveryId: string) {
    return this.http.get<any>(
      `${environment.apiUrl}recoveryPassword/${recoveryId}`
    );
  }
  getalluserByRecoverId(uuid: string) {
    /*  
   * recovery UUID changed get method to post method
   */
    let uuidparams = {}

    uuidparams['uuid'] = uuid

    this.encryptInfo = this.security.encrypt(uuidparams)
    console.log(this.encryptInfo);
    return this.http.post<any>(`${environment.apiUrl}allDetails`, this.encryptInfo);
    // return this.http.post<any>(
    //   `${environment.apiUrl}allDetails`,uuidparams
    // );
  }
  getverifyDetailsByRecoverId(verifyId: string) {
    /*  
  *verifyId changed get method to post method
  */
    let verifyparams = {}

    verifyparams['recoverId'] = verifyId

    this.encryptInfo = this.security.encrypt(verifyparams)


    return this.http.post<any>(`${environment.apiUrl}verifyLink`, this.encryptInfo);

    // return this.http.post<any>(`${environment.apiUrl}verifyLink`, verifyparams);
    // return this.http.get<any>(
    //   `${environment.apiUrl}verifyLink/${verifyId}`
    // );
  }
  checkUserName(payload: username) {
    /*  
  *username changed get method to post method
  */

    let params = {}
    params['name'] = payload
    console.log(params);

    this.encryptInfo = this.security.encrypt(params);
    console.log(this.encryptInfo);
return this._ComnonSERV.commonMethod(checkUserNames,POST).subscribe({
  next:(result)=>{
    console.log(result);
    result= this.encryptInfo
  }
  
})

    // return this.http.post<any>(`${environment.apiUrl}checkUserName`, this.encryptInfo);
   

  }
  checkPersonalEmail(payload: emailId) {
    /*  
  *emailId changed get method to post method
  */
    let mailparams = {}
    mailparams['emailId'] = payload


    this.encryptInfo = this.security.encrypt(mailparams)
// return this._ComnonSERV.commonMethod(checkUserNames,POST).subscribe({
//   next:(result)=>{
//     console.log(result);
//     result= this.encryptInfo
//   }
  
// })
    return this.http.post<any>(`${environment.apiUrl}checkPersonalEmail`, this.encryptInfo);


  }
  getUserById(loginId: string) {
    return this.http.get<any>(`${environment.apiUrl}getUserById/${loginId}`);

    // return this.http.get<any>(`${environment.apiUrl}getUserById/${loginId}`);
  }

  otpexperied(payload: string) {
    return this.http.post<any>(`${environment.apiUrl}expiredotp`, payload
    );
  }
  backendlogout(){
    return this.http.get<any>(`${environment.apiUrl}signout`
    );
 
  }
}
// Declarations of REQUEST/REPONSE TYPES
export type signupRequest = {
  contactNumber: string;
  dateOfBirth: Date;
  emailId: string;
  firstName: string;
  lastName: string;
  omailUserId: number;
  password: string;
  recoveryEmailId: string;
  // status: number;
  userName: string;
  hintfroMemorableWord: string,
  isMemorable: boolean,
  memorableWord: string,
  isOtp: boolean,
};
export type loginRequest = {
  email: string;
  password: string;
  isMemorable: boolean;
  isOtp: any;
  length: any

  // email: string,
  // isMemorable: true,
  // isOtp: "string",
  // keys: [
  //   0
  // ],
  // "length": 0,
  // "values": [
  //   "string"
  // ]
};
export type emailvalidateRequest = {
  email: string; isMemorable: boolean; otp: any; keys: any; length: any; values: string, role: string
}
// export type  emailCheck ={
//   recoveredEmail: string
// }
export type username = {
  name: any
  emailId: any;
  id: string;
}
export type emailId = {

  emailId: any
}
export type autoLoginRequest = {
  // email: string;
  // password: string;
  userName: string;
  encrypted: any;
};

export type loginResponse = {
  jwtToken: string;
  message: string;
  response: any;
  values: string;
  status: string;
};
