<div class="faqs-page">
  <section class="faqs-comp-1">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div>
            <h3>FAQ</h3>
            <h4>Looking for something else?</h4>
            <p>Here are some of the frequently asked questions.</p>
          </div>
        </div>
        <div class="col-lg-6">
          <img src="/assets/images/faq.png" class="img-fluid" alt="faq" />
        </div>
      </div>
    </div>
  </section>
  <section class="faq-wrapper">
    <div class="container">
      <div class="faq-content">
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                What do CC and BCC mean?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>When sending an email, CC stands for Carbon Copy and BCC stands for Blind Carbon Copy.</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Is email secure?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>How secure your email is will depend on which provider you use to send your email. Some providers, like
              Protonmail, use end-to-end encryption which means only you and the recipient can read the contents of an
              email.</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                What should I do if my email is hacked?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>The very first thing you should do is change your password. It shouldn’t be a password you’ve used
              elsewhere and it shouldn’t be something that’s easy to guess or remember.</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                What’s the difference between IMAP and POP3?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>POP3 and IMAP are what’s known as incoming email protocols. With POP3, an email is downloaded from a mail
              server to a device and then usually deleted from the server, meaning the only copy exists on the device to
              which it was downloaded.</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Are email addresses case sensitive?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>No, they’re not, which means you can type out your email addresses any way you like and they will still
              reach the intended recipient as long as there are no spelling errors.</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Can emails be blocked?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Emails can be blocked, depending on which email provider you use. Services like Google, Yahoo, and
              Outlook
              all provide ways to block senders.</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Why do some emails go to spam?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>There are a number of reasons why your emails might be ending up in people’s spam or junk folders, but
              among the most common are you’ve included attachments, your email body contains too many links or spam
              trigger words, or you’ve added too many addresses to the BCC field.</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Can email addresses be traced?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>The answer to this question also depends on which email provider you use. Every email includes what are
              known as ‘headers’, which are pieces of information about how the email was sent. Once you have an email’s
              header data, you can use online tools to determine which IP address it was sent from. Some email service
              providers will include the IP address of the person who actually sent the email, while others, like Gmail,
              will only show a Google IP address.</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Where are emails stored?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>If your email account is set up with the IMAP protocol, then emails are stored in the cloud on a mail
              server. If you’re using POP3 instead, usually the email will only be stored on the device to which it was
              downloaded, although some email software gives you the option of keeping a copy on the mail server too.
            </p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Can email addresses have spaces?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Like any other address on the Internet, email addresses can’t have spaces, although you can break words
              up with periods, dashes, or underscores.</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Do emails expire?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Some email providers, like Protonmail, include a feature whereby email messages can be set to expire
              after a period of time of your choosing.</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Why is my email stuck in the outbox?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>There are many reasons why this could happen but some are that you’ve included attachments that are too
              large, you have an antivirus program which is scanning outgoing emails and has prevented the sending, or
              the mail server you’re sending to is offline.</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Do emails take up space?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Yes, all emails will take up space. How much depends on the contents of the email and whether there are
              any attachments or not.</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Do emails have read receipts?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>This is another feature that depends on your email provider. Some have it available, others do not. For
              example, Google’s Gmail for personal use doesn’t, but G Suite for business does.</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Can emails be unsent?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Again, it depends on your email provider. Some include an ‘undo send’ feature whereby you can set how
              long after you’ve sent an email that you have to undo the action.</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                What is IMAP 
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>IMAP means Internet Message Access Protocol; it is a standard protocol for using e-mail service from your
              local server.</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Reduce the size of pictures and attachments in Omail email messages
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Photos and other types of attachments can vary in size. Many email systems, including Gmail, Yahoo, and
              Exchange limit the size of email messages you can send. If you're concerned about the size of your email
              messages or if you've received a message that your email is too large to send, there are several steps you
              can take to reduce the size of the attached pictures and other documents. </p>
            <p>In addition to restricting the size of individual messages, some email systems put a cap on the total
              size of your mailbox. Since each message you send is stored in your Sent Items folder, reducing the size
              of attachments can also help keep your mailbox size small. </p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Add delivery receipt to track an e-mail message
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>A delivery receipt tells you that an email message was delivered to the recipient's mailbox, but not
              whether the recipient has seen it or read it. A read receipt tells you that a message was opened. In both
              cases, you receive a message notification in your Inbox.</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Flag email messages for follow up
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>By flagging email messages, you can track responses to messages that you send. You can also make sure
              that you follow up on messages that you receive. In either case, you can include a reminder alert.</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                How To Empty The Trash And Remove Deleted Items?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Tools > Options > “Other” Tab > Check “Empty the Deleted Items folder upon existing”</p>
            <p>When your mail is deleted it goes into the Deleted Items folder which is similar to your trash in Eudora.
              The major difference is, once you empty your deleted items it will be recoverable for 14 days. Anything
              that’s been deleted for 14 days can no longer be recovered.</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                How To Create An Address Book?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>If you highlight any email address and right click it, you will get a context menu. On that menu select
              Add email address to contact list.</p>
            <p>When in the Contact window, you might have several contacts. There is one address book in your Personal
              Folder and there is one for main Mailbox, which also will be available to you in the Web client. So your
              address book can follow you.</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                How To Customize The View Of My Mailboxes?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Edit columns Add / Remove Field: View > Current View > Customize Current View > Fields > Add / Remove
              Fields.</p>
            <p>Find items size of a folder:</p>
            <p>Select the folder, number of items show at the left bottom corner.</p>
            <p>Find Folder size:</p>
            <p>Right click the folder to select Properties, click “Folder size” at General Tab.</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                What Is Timeframe For Conversion?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>The Library will be converted this Spring. One weekend early this summer, email and calendaring will be
              unavailable while data from Meeting Maker is brought over into Exchange. The Meeting Maker system will
              then be shutdown and that Monday you will begin using calendaring in Omail. Until then, all meetings
              should originate in Meeting Maker. To ensure a smooth transition, everyone must be on Exchange prior to
              June 1.</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                How To Change The Font style?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>The default Message Font is 11-point Caliber. To change the default font for new messages:
              Tools > Options > Mail Format > Stationery and Fonts > Personal Stationery > New mail message > Font</p>
            <p>To change Mail List Font: <br>
              View > Current View > Customize Current View > Other Settings > Column Font</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                How Much Mail Can Be Stored In Each Mailbox?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>The recommendation is 5000 messages per folder.</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                How To Delete Mail?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>You can delete one message or many messages. Select the message(s) you want to delete and click the
              Delete Mail icon or press the Delete key on your keyboard.</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                How To Setup A Group List?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Click the New Button > Distribution List > Select New Member > Search in Contact</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                How To Create A Signature?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Options > Mail Format > Signature or Click the signature icon when composing the message.</p>
            <br><br>
            <p>Type the signature name<br>
              Type in the signature content<br>
              Select the Default Signature for New messages and for Reply/Forwards.</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                How To Enable Automatic Spell Checking?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Tools: > Options > Spelling Tab > check “Always check spelling before sending”</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                How To Create Folders To Organize My Mail?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>To do this right-click either your main mailbox or personal folder list. Select New Folder. Name the
              Folder and click OK.</p>
            <p>To move mail into the folder simply click and drag it into the folder. Ctrl + Click lets you select
              multiple messages that aren’t necessarily next to each other and Shift + Click lets you select a series of
              messages.Then you can drag and drop those messages into the new folder.</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                How To Search For Text Within A Message?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Open the message. (This cannot be done while in the preview pane). Press F4 to bring up the search dialog
              box or Click Find (the binoculars icon). Enter your search criteria.</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Email Ports?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>For networks, a port means an endpoint to a logical connection. The port number identifies what type of
              port it is. Here are the default email ports for: –</p>
            <p>POP3 – port 110<br>
              IMAP – port 143<br>
              SMTP – port 25<br>
              HTTP – port 80<br>
              Secure SMTP (SSMTP) – port 465<br>
              Secure IMAP (IMAP4-SSL) – port 585<br>
              IMAP4 over SSL (IMAPS) – port 993<br>
              Secure POP3 (SSL-POP) – port 995</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                How To Clear The Search History?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>The Contact Quick Find feature stores each search. If an item is already in the list, you can select it
              quickly for a repeat search. But the list can rapidly grow too large to be helpful. Outlook stores search
              items in the Registry. To clear the list, delete the following Registry key:</p>
            <p>HKEY_CURRENT_USERSoftwareMicrosoftOfficeversionOutlookQuickFindMRU</p>
            <p>Note: Editing the Registry is risky, so be sure you have a verified backup before saving any changes.</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                How To Make Contacts Appear In The Address Book?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>When addressing e-mail, you can choose from Contacts items — usually. If contacts don’t appear,
              right-click the Contacts folder, choose Properties, and then click the Omail Address Book tab. Make sure
              the Show This Folder As An Email Address Book is checked. If that option is dimmed, choose E-mail Accounts
              from the Tools menu and then choose View Or Change Existing Directories Or Address Book and do one of the
              following:</p><br>
            <p>If Omail Address Book isn’t in the list, add it. Then, close Outlook and restart it.
              If Omail Address Book is listed, delete it, close Outlook, restart, and then add it.</p>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                How To Delete Omail Folders?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Adding folders is so easy that you might add more folders than you actually need. It’s common to create
              too many and then find you need to cut back a bit. Fortunately, deleting folders is easy: </p> <br>
            <p>Right-click the folder you want to delete.<br>
              Choose Delete foldername.<br>
              That’s it! Outlook will move the deleted folder to the Deleted Items folder, as a subfolder. If you want
              to permanently delete the folder, right-click the deleted folder (in the Deleted Items folder) and choose
              Delete. When Outlook prompts you to permanently delete the folder, click Yes. Otherwise, the data in that
              folder is still in the Delete Items folder, consuming resources. In addition, if a folder contains
              sensitive or confidential data, it’s visible to anyone who accesses your computer. If Delete isn’t
              available with a right-click, call your administrator.</p>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </section>
</div>
