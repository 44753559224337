import { createReducer, on } from '@ngrx/store';
import * as AuthActions from './auth.actions';

export const authFeatureKey = 'auth';

export interface State {
  isAuthenticated: boolean;
  access_token: string;
}
export interface State {
  isAuthenticated: boolean;
  access_token: string;
}
export interface State {
  isAuthenticated: boolean;
  access_jwtToken: string;
}
export const initialState: State = {
  isAuthenticated: false,
  access_jwtToken: '',
  access_token: ''
};

export const reducer = createReducer(
  initialState,

  on(AuthActions.login, (state) => ({
    ...state,
  })),
  on(AuthActions.validateEmail, (state) => ({
    ...state,
  })),
  on(AuthActions.memorable, (state) => ({
    ...state,
  })),
  on(AuthActions.loginFailure, (state) => ({
    ...state,
  })),
  // on(AuthActions.otpFailure, (state) => ({
  //   ...state,
  // })),
  on(AuthActions.memorableFailure, (state) => ({
    ...state,
  })),
  on(AuthActions.autoLogin, (state) => ({
    ...state,
  })),
  // on(AuthActions.autologinSuccess, (state, { access_token }) => ({
  //   ...state,
  //   isAuthenticated: true,
  //   access_token: access_token,
  // })),
  on(AuthActions.otpSuccess, (state, { access_jwtToken }) => ({
    ...state,
    isAuthenticated: true,
    access_token: access_jwtToken,
  })),
  on(AuthActions.autologinFailure, (state) => ({
    ...state,
  })),
);
