<div class="sign-fm">
  <div class="logo">
    <a routerLink="/" class="logo"><img src="/assets/images/fm-logo.svg" routerLink="/" alt="O-Mail" /></a>
  </div>
  <div class="form-header">
    <h3>Login</h3>
  </div>
  <form>
    <h5>Enter the following characters to login</h5>
    <div class="row text-center">
      <div class="col-md-4">
        <div>
          <mat-label>2nd</mat-label>
          <mat-form-field appearance="outline" class="w-100">
            <input matInput #input>
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-4">
        <div>
          <mat-label>4th</mat-label>
          <mat-form-field appearance="outline" class="w-100">
            <input matInput #input>
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-4">
        <div>
          <mat-label>6th</mat-label>
          <mat-form-field appearance="outline" class="w-100">
            <input matInput #input>
          </mat-form-field>
        </div>
      </div>
    </div>    
    <div class="form-btn">
      <button type="submit" class="btn-block">Login</button>
    </div>
  </form>
  <div class="redirect-link">
    Have you an account? <a routerLink="/sign-in">Login</a>
  </div>
  <div class="redirect-link">
    Don’t have an account? <a routerLink="/sign-up">Register</a>
  </div>
</div>
