<div class="privacy-policy-page">
  <section>
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <h3>Privacy Policy</h3>
          <p>ONPASSIVE operates https://www.onpassive.com (the “Site”). This page informs you of our policies regarding
            the collection, use and disclosure of Personal Information we receive from users of the Site. We use your
            Personal Information only for providing and improving the Site. By using the Site, you agree to the
            collection
            and use of information in accordance with this policy.</p>
          <h4>Information We Collect</h4>
          <p>When you create ONPASSIVE account we ask for personal information such as: </p>
          <ul>
            <li>Your name, mailing address, phone number, email address, and other contact information.</li>
            <li>Your country of residence.</li>
            <li>Your anticipated availability to begin work. and</li>
            <li>The names and contact information for those you refer under our business plan program.</li>
          </ul>
          <p>Founder/client/customers provide any personal information you have chosen to disclose to us by including it
            in the file your basic details upload. If you decide to contact us directly or through an internet
            application, we will receive the contact information you provided so that we could respond and any other
            personal information you chose to include in your message. You may elect not to provide us with all of the
            information we request. . However, if you do not provide the information we have requested, we will not be
            able to provide you with an account or use our internet applications/software that facilitate you. We may
            combine information we collect about you with other information we receive from additional sources, such as
            information you may have provided to us by other means. Whether to provide your personal data is at your
            sole
            discretion. However, if the personal data you provided is reported or found to be insufficient to identify
            you, stolen from or in the name of any other person(s), or untrue, you will be deprived of rights or
            interest
            related to your participation in the event. Unless with your consent or otherwise provided by law, in this
            event (on this website) we will not disclose your personal data to any third party or use them beyond the
            scope of the purpose(s) of collection</p>
          <h4>How We Use Your Information</h4>
          <p>ONPASSIVE uses your information to provide you services. We also may use your information for a variety of
            related purposes and other business functions, such as:</p>
          <ul>
            <li>To fulfil your requests for information and to communicate with you, such as alerting/upgrade/downgrade
              your level as per your performance.</li>
            <li>Provide you with services, training, consultation, assistance</li>
            <li>To assess whether you are qualified for a business levels.</li>
            <li>To consider and address feedback you may provide to us.</li>
            <li>To inform our clients, founder and business partners about our services.</li>
            <li>To help us administer or improve our business and our services, the applications and other processes we
              use to deliver those services, and the promotional materials we have developed to describe those services.
            </li>
            <li>To send you the business proposal subject to your eligibility</li>
            <li>To perform certain research and analyses</li>
            <li>To protect the integrity of or to improve our websites, information systems, and security;</li>
            <li>To prevent physical harm or financial loss; and</li>
            <li>For certain administrative purposes, such as risk management, to fulfil legal obligations or address
              compliance needs, and to resolve or defend legal claims.</li>
          </ul>
          <h4>Retention</h4>
          <p>All Personal Information collected form the Founder/ customer/ member under the point ‘information we
            collect’ at the time of registration will be retain for as long as your account remains in existence and for
            such period thereafter as required under any law for the time being in force.</p>
          <h4>Rights</h4>
          <p>As a registered user, you have the right to access you profile at any time to add, remove or modify any
            registration information that you have supplied.</p>
          <h4>Protection of Personal Data</h4>
          <ul class="mt-0">
            <li>We have installed firewall software, anti-virus software, and other relevant and necessary website
              security measures in the computer hosting this website in order to properly protect your personal data.
              Only
              authorized personnel can access your personal data. All personnel who may access your personal data shall
              have signed a confidentiality Privacy policy, and shall assume legal liabilities for violation of the
              Privacy policy.</li>
            <li>If owing to operational needs of this event (this website), services from other institutions are
              required,
              we will ask such institutions to keep your personal data in strict confidence and will conduct necessary
              inspections on them to ensure their compliance.</li>
          </ul>
          <h4>Log Data</h4>
          <ul class="mt-0">
            <li>Like many site operators, we collect information that your browser sends whenever you visit our Site
              (“Log
              Data”).</li>
            <li>This Log Data may include information such as your computer’s Internet Protocol (“IP”) address, browser
              type, browser version, and the pages of our Site that you visit, the time and date of your visit, the time
              spent on those pages and other statistics.</li>
          </ul>
          <h4>International Data Transfer</h4>
          <p>ONPASSIVE is a global company that operates in numerous worldwide locations. We also work with business
            partners and clients in multiple worldwide locations. We may transfer the personal information we collect to
            any country where we do business. Those countries may not have the same data protection laws as the country
            in
            which you reside. No matter where we transfer personal information collected, that information will be
            subject
            to the policies and protections described by this notice.</p>
          <h4> Sensitive Personal Data or Information (“spdi”)-</h4>
          <p>(a) means passwords, financial information, such as bank account or credit card or debit card or other
            payment instrument details, any detail relating to the above as provided to COMPANY for providing service;
            and
            any information received under the above by COMPANY for processing, stored or processed under lawful
            contract
            or otherwise,(b) COMPANY is required to obtain prior consent from the information provider regarding the
            purpose of usage of the SPDI. Such information should be collected only if it is essential and required for
            a
            lawful purpose connected with the functioning of the COMPANY, (c) Founder/Customer has no right to
            share/disclose the SPDI details in public and, if found to be in violation, that Company has legal right to
            terminate his/her registration without notice, (d) Founder/Customer agrees and consents that Company has
            right
            to use and disclosed his/her data for the information sought by governmental agencies or under applicable
            legal provisions and for COMPANY business requirements, and further agrees Founder/ Customer has no rights
            to
            claim, sue, or approach for any Legal remedy for such disclosure.</p>
        </div>
        <div class="col-lg-6">
          <img src="/assets/images/privacy-policy.png" class="img-fluid" alt="privacy-policy" />
          <h4>Covenant Not to Sue</h4>
          <p>Founder/Customer shall not institute any action or suit at law or in equity against COMPANY, nor institute,
            prosecute or in any way aid in the institution or prosecution of any claim, demand, action, or cause of
            action
            arising out of the CONFIDENTIAL INFORMATION or any INTELLECTUAL PROPERTY thereof, including but not limited
            to, claim, demand, action, or cause of action for invalidating any INTELLECTUAL PROPERTY and CONFIDENTIAL
            INFORMATION of the COMPANY.</p>
          <h4>Damages and Specific Performance</h4>
          <p>FOUNDER/CUSTOMER agrees that should FOUNDER/CUSTOMER breach any portion contained in this Privacy policy
            that
            COMPANY would suffer irreparable harm and COMPANY would be without adequate remedy at law and that COMPANY
            may
            obtain injunctive relief, including specific performance of the Privacy policy, as well as monetary award
            for
            damages suffered by COMPANY for FOUNDER/CUSTOMER’S breach of this Privacy policy.</p>
          <h4>Publishing offensive, false or threatening information</h4>
          <p>Founder/ Customer who sends by any means of a computer resource any information that is grossly offensive,
            which relates with the term of defamation whether slander or libel or has a menacing character; or any
            information which he knows to be false (viz. sharing marketing campaigns structure or information and other
            confidential Information), but for the purpose of causing annoyance, inconvenience, danger, obstruction, or
            insult, etc., shall be punishable by Law and this action of breach shall result in violation of privacy
            policy
            and suspension or termination of his/her registration/enrolment as Founder/ Customer.</p>
          <h4>Severability</h4>
          <p>Should a court of competent jurisdiction find that any portion of this Privacy policy is invalid, illegal,
            or
            unenforceable, the remaining provisions shall remain in full force and effect, and the parties shall use
            reasonable efforts to substitute a valid, legal, and enforceable provision that implements purposes of the
            provision so held invalid, illegal, or unenforceable to any extent permissible under the law.</p>
          <h4>Governing law and jurisdiction</h4>
          <p>The establishment, validity, execution and dispute settlement of this Privacy policy shall be governed by
            the
            laws of the Country. The Courts located at the registered office of the COMPANY shall have the exclusive
            jurisdiction to entertain and resolve all the disputes between the parties</p>
          <h4>Communications</h4>
          <p>We may use your Personal Information to contact you with newsletters, marketing, digital marketing or
            promotional materials and other information if required.</p>
          <h4>Cookies</h4>
          <p>Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are
            sent to your browser from a web site and stored on your computer’s hard drive. Like many sites, we use
            “cookies” to collect information. You can instruct your browser to refuse all cookies or to indicate when a
            cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our
            Site.</p>
          <h4>Security</h4>
          <p>The security of your Personal Information is important to us, but remembers that no method of transmission
            over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>
          <h4>Disclosures and Transfer</h4>
          <p>Save as otherwise set out in the point ‘How We Use Your Information’ with respect to information provided
            to
            us , will not share/disclosed and transferred to any third party.</p>
          <h4>Disclaimer</h4>
          <p>The Website is being made available as on service provider basis and the ONPASSIVE makes no warranties of
            any
            kind, whether express, implied, statutory or otherwise, with respect to functioning of the website. All
            services those provided by ONPASSIVE ‘s website are never wholly free form defects, bugs and errors and
            ONPASSIVE provides no warranty or representation to that effect or that website will be compatible with any
            software not specifically identified as compatible.ONPASSIVE specifically disclaims any implied warranties
            of
            non-infringement. The functioning of the website and services totally depends upon the compliance of the all
            registered users with these terms.</p>
          <h4>Updates to Our Privacy Notice</h4>
          <p>This notice may be updated without prior notice to you to reflect changes in our information collection,
            use
            and security practices. If the notice is updated, it shall be your responsibility to keep track of such
            updated notice.</p>
          <h4>Grievances</h4>
          <p>If you have any concern or question in relation to this Privacy Policy, you may address them to this EMAIL
            Address- legal@onpassive.com</p>
        </div>
      </div>
    </div>
  </section>
</div>
