<div class="why-omail-page">
  <section class="why-om-comp-1">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <h4>Whats is O-Mail</h4>
          <h6>Software made to make email easier.</h6>
          <p>O-Mail is a free and open source email, newsfeed, chat, and calendaring client, that’s easy to set up and
            customize. One of the core principles of O-Mail is the use and promotion of open standards - this focus is a
            rejection of our world of closed platforms and services that can’t communicate with each other. We want our
            users to have freedom and choice in how they communicate.</p>
        </div>
        <div class="col-lg-6">
          <img src="/assets/images/why-omail.png" class="img-fluid" alt="why-omail" />
        </div>
      </div>
    </div>
  </section>

  <section class="why-om-comp-2">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6">
          <img src="/assets/images/who-we-are.png" class="img-fluid whoweare-img" alt="who-we-are"  />
        </div>
        <div class="col-md-6">
          <h4>Who we are</h4>
          <h6>We are a community making communication free and open.</h6>
          <p>There is a passionate team of volunteers involved in developing O-Mail and assisting its users who would
            welcome your involvement. Check out our Get Involved page to learn how you can participate on the O-Mail
            team.
            We also have paid staff working full-time on Onpassive, funded by donations from our users. If you’re
            curious
            about who is working on Onpassive, check out the list of our core contributors.
          </p>
          <h4>Thunderbird Council</h4>
          <p class="mb-0">The O-Mail project is governed by seven councilors, elected by the O-Mail community’s active
            contributors.
            You can see the current elected O-Mail Council members on the O-Mail wiki.</p>
        </div>
      </div>
    </div>
    <section class="why-om-comp-3">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-lg-6">
            <div class="get-involved">
              <div class="icon-circle">
                <img src="/assets/images/get-involved.svg" class="img-fluid" alt="get-involved" />
              </div>
              <h3>Get Involved</h3>
              <p>O-Mail is an open source project, which means anyone can contribute ideas, designs, code, and time
                helping
                fellow users.</p>
            </div>
          </div>
          <div class="col-md-12 col-lg-6">
            <div class="get-involved">
              <div class="icon-circle">
                <img src="/assets/images/mail.svg"  class="img-fluid" alt="mail" />
              </div>
              <h3>O Mail</h3>
              <p>O-Mail is a free email application that’s easy to set up and customize - and it’s loaded with great
                features!</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</div>
