import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { user } from 'src/app/shared/models/profile.model';
import { EncrDecrService } from 'src/app/util/encr-decr.service';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { CommonService } from '../services/common.service';
@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  profilePic = new BehaviorSubject<any>('null');
  key!: any;

  public encryptInfo;
  public decryptedInfo;

  constructor(private http: HttpClient,private security:CommonService) {
    this.key = environment.accessKey;
    //this.profilePic.next('null');
  }
  setProfilePic(imageUrl:any){
    this.profilePic.next(imageUrl);
  }
  getProfilePicUrl():Observable<any>{
    return this.profilePic.asObservable();
  }
  userDetails() {
    
    return this.http.get<user>(`${environment.apiUrl}getProfile`);
  }

  updateUserDetails(data: any) {
    this.encryptInfo=this.security.encrypt(data)
   return this.http.put<user>(`${environment.apiUrl}updateProfile`, this.encryptInfo);
  
  }

  changePassword(data: any) {
    this.encryptInfo=this.security.encrypt(data)
    return this.http.put<any>(`${environment.apiUrl}updatePassword`, this.encryptInfo);
  }
  updateProfilePic(formData: any) { 
    
    // this.encryptInfo=this.security.encrypt(formData)
    return this.http.post<any>(`${environment.apiUrl}uploadProfilePicture`,formData );
   
  }
  deleteProfilePic() {
    return this.http.delete<any>(`${environment.apiUrl}deleteProfilePicture`);
  }
  getProfilePic() {
 
    return this.http.get(`${environment.apiUrl}getProfilePicture`,{ responseType: 'text' });
  }
  updateAutoReply(formData:any) {
    this.encryptInfo=this.security.encrypt(formData)
 
 return this.http.post(`${environment.apiUrl}saveOrUpdateVacation`,this.encryptInfo,{})
 
  }
  getUserProfile() {
    
    return this.http.get(`${environment.apiUrl}getProfile`);
  }
  getUserSettings(data:any) {
    this.encryptInfo=this.security.encrypt(data)
    return this.http.post<any>(`${environment.apiUrl}sideBarView`,this.encryptInfo);
  }
  UserSettingResize(data){
    this.encryptInfo=this.security.encrypt(data)
    return this.http.post<any>(`${environment.apiUrl}SideBarResize`,this.encryptInfo);
  }
  ReadReceipt(data){
    this.encryptInfo=this.security.encrypt(data)
    return this.http.post<any>(`${environment.apiUrl}emailReadReceipt`,this.encryptInfo);
  }
  
  getUsersidebar(){
    return this.http.get(`${environment.apiUrl}getAllSideBarView`);
  }
  maildisplay(data){
    this.encryptInfo=this.security.encrypt(data)
    return this.http.post<any>(`${environment.apiUrl}disPlayPane`,this.encryptInfo);
  }
  getMaildisplay(){
    return this.http.get(`${environment.apiUrl}getdisPlayPan`);
  }

  getTimezone(params:any){
    // return this.http.get(`${environment.apiUrl}getTimeZone/${params}`);
     /*  
   *getTimeZone changed get method to post method
   */
    let timeparams={}
   
    timeparams['timeZone']=params
    this.encryptInfo=this.security.encrypt(timeparams)
  // this.encryptInfo = CryptoJS.AES.encrypt(JSON.stringify({data}), this.key).toString();
  

    return this.http.post<user>(`${environment.apiUrl}getTimeZone`, this.encryptInfo);
    // return this.http.post<any>(
    //   `${environment.apiUrl}getTimeZone`,timeparams
    // );
    
  }
  mailrules(data){
    this.encryptInfo=this.security.encrypt(data)
    return this.http.post<any>(`${environment.apiUrl}saveEmailRules`,this.encryptInfo);
  }
  settingactionandcondition(){
    return this.http.get(`${environment.apiUrl}getConditionandActiondata`);
  }
  allemailrules(){
    return this.http.get(`${environment.apiUrl}getAllEmailRules`);
  }

  deleteEmailRule(payload){   
    this.encryptInfo = this.security.encrypt(payload)
    return this.http.post<any>(`${environment.apiUrl}deleteEmailRules`, this.encryptInfo);
  }
  updateAutoRespond(formData:any) {
    this.encryptInfo=this.security.encrypt(formData)
    return this.http.post(`${environment.apiUrl}saveOrUpdateAutoSendEmail`,this.encryptInfo,{}) 
  }
  allAutoresponds(){
    return this.http.get(`${environment.apiUrl}getAutoSendEmails`);
  }
  deleteAutoRes(payload){   
    this.encryptInfo = this.security.encrypt(payload)
    return this.http.post<any>(`${environment.apiUrl}deleteAutoSendEmail`, this.encryptInfo);
  }
  autoRespondToggle(payload){
    this.encryptInfo = this.security.encrypt(payload)
    return this.http.put<any>(`${environment.apiUrl}activateOrDeactivate`, this.encryptInfo);
  }
}


