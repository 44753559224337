<section class="auth-log-form">
  <div class="go-login-inn">
    <div class="registration-circlebg" id="animate-area">
      <div class="container">
        <div class="row align-items-center h_100vh">
          <div class="col-md-8 offset-md-2 login-form">
            <div class="auth-form-block form-layout" #formAnim>
              <div>
                <router-outlet></router-outlet>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
