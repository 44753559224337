// HTTP Methods
export const GET = "GET";
export const POST = "POST";
export const PUT = "PUT";
export const DELETE = "DELETE";

// login end point 

export const LOGIN_ENDPOINT='login';
export const GET_CATEGORIES='categories.php';
export const ADD_CATEGORY='create-category.php';
export const UPDATE_CATEGORY='update-category.php';
export const DELETE_CATEGORY='delete-category.php';
export const checkUserNames='checkUserName';
export const PersonalEmail='checkPersonalEmail';
export const GET_PRODUCTS='products.php';
export const ADD_PRODUCT='create-product.php';
export const UPDATE_PRODUCT='update-product.php';
export const DELETE_PRODUCT='delete-product.php';

export const CHANGE_PASSWORD='change-password';

//Folder Color Codes

export const COLOR_CODES = [
    { code: '#790252'},
    { code: '#16003B' },
    { code: '#7A0BC0' },
    { code: '#8B9A46' },
    { code: '#a45c40' },
    { code: '#C84B31' }
  ];