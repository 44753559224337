import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ContactusService } from 'src/app/core/http/contactus.service';
import { AppState } from 'src/app/store';
import { snackBarOpen } from 'src/app/store/snack-bar/snack-bar.actions';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  contactusForm!: FormGroup;
  submitted = false;
  val: any;

  constructor(private formBuilder: FormBuilder,
    private contactusService: ContactusService,
    private router:Router,
    
    private store:Store<AppState>,) { }

  ngOnInit() {
      this.contactusForm = this.formBuilder.group({
          firstName: ['', Validators.required],
          lastName: ['', Validators.required],
          email: ['', [Validators.required, Validators.email]],
          phoneNumber: ['', Validators.required],
          message:['', Validators.required  ]
      });
  }

  // convenience getter for easy access to form fields
  get f() { return this.contactusForm.controls; }

  onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      if (this.contactusForm.invalid) {
          return;
      }

      console.log('SUCCESS!! :-)\n\n' + JSON.stringify(this.contactusForm.value))
  }
  contactUs() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.contactusForm.invalid) {
        return;
    }
  console.log(this.contactusForm.value);
  const val = this.contactusForm.value;
  this.contactusService.contactUs(val).subscribe((res) => {
    console.log(res)
    this.val = res;
  });
  console.log(this.contactusForm.value);
     
  // this.exceptionMessage = const data;
  const msg = 'Thanks For contactus with Omail';
  this.refresh();
  this.store.dispatch(
    snackBarOpen({
      message: msg,
      config: {
        horizontalPosition: 'end',
        verticalPosition: 'top',
        panelClass: ['snack-bar-success'],
      },
    })
  );
}
refresh() {
  let currentUrl = decodeURIComponent(this.router.url);
  // console.log(currentUrl);
  this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    this.router.navigate([currentUrl]);
  });
}
 
}
