<section class="site-header">
  <div class="container-fluid">
    <div class="site-header-block">
      <div class="site-header-left">
        <span (click)="openNav()" class="side-menu-icon">
          <mat-icon>menu</mat-icon>
        </span>
        <div id="mySidenav" class="sidenav" #myNameElem>
          <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>
          <a (click)="closeNav()" routerLink="/">Home</a>
          <a (click)="closeNav()" routerLink="/about-us">About us</a>
          <a (click)="closeNav()" routerLink="/why-omail">Why O-Mail</a>
          <a (click)="closeNav()" routerLink="/feature">Features</a>
          <a (click)="closeNav()" routerLink="/faqs">Faq</a>
          <a (click)="closeNav()" routerLink="/contact-us">Contact-us</a>
          <a (click)="closeNav()" routerLink="/privacy-policy">Privacy Policy</a>
          <a (click)="closeNav()" routerLink="/terms-and-conditions">Terms and conditions</a>
          <a (click)="closeNav()" routerLink="/sign-in">Login</a>
          <a (click)="closeNav()" routerLink="/sign-up">Register</a>
        </div>
        <a routerLink="/" class="logo"><img src="/assets/images/main-logo.svg" alt="logo" /></a>
        <!-- <a class="all-product-block" href="#"><img src="../../../assets/images/omail-site/dots-menu.svg"
            alt="allproducts" /></a> -->
        <a class="whyomaillink" routerLink="/why-omail">Why O-Mail</a>
        <a class="featurelink" routerLink="/feature">Features</a>
      </div>
      <div class="site-header-right">
        <!-- <div class="language-block">
          <div class="language-button"> <span>English </span> <i class="fa fa-angle-down"></i></div>
          <div class="language-list">
            English
          </div>
        </div> -->
        <button class="rig-button-fill" routerLink="/sign-up">Register</button>
        <button class="login-button-fill" routerLink="/sign-in">Login</button>
      </div>
    </div>
  </div>
</section>
