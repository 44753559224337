import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer,
} from '@ngrx/store';
import { storeReset } from 'ngrx-store-reset';
import { isLoadingReducer, isLoadingState } from './loader/loader.reducer';
import { snackBarReducer, SnackBarState } from './snack-bar/snack-bar.reducer';
import { synStateMetaReducer } from './sync-state/sync-state.reducer';

export interface AppState {
  loader: isLoadingState;
  snackBar: SnackBarState;
  // router: RouterReducerState;
}

export const reducers: ActionReducerMap<AppState> = {
  loader: isLoadingReducer,
  snackBar: snackBarReducer,
  // router: routerReducer,
};

export const selectRouter = createFeatureSelector<RouterReducerState>('router');

export const getRouter = createSelector(selectRouter, (state) => state.state);

// Pass your action type (the one you'd like to reset the state) to the metareducer
export function storeResetMetaReducer(
  reducer: ActionReducer<AppState>
): ActionReducer<AppState> {
  return storeReset({ action: '[Auth] Logout' })(reducer);
}

export const metaReducers: MetaReducer<AppState>[] = [
  storeResetMetaReducer,
  synStateMetaReducer,
];
