<section class="auth-log-form">
  <div class="white-circle">
    <div class="go-login-inn">
      <div class="container">
        <div class="row align-items-center h_100vh">
          <div class="col-md-6 login-left-content">
            <div>
              <div class="title-head main-title" #animateTitle>
                <h1>You are so in it to win it</h1>
              </div>
              <div #imgWrap class="img-wrap mb-3 mb-md-0">
                <img src="../../../../assets/images/loginflow/login-banner.png" alt="login-img" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="auth-form-block form-before-bg" #formAnimate>
              <div>
                <router-outlet></router-outlet>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
