import { createAction, props } from '@ngrx/store';

export const isLoading = createAction(
  `[Shared] Loading...`,
  props<{ isLoading: boolean }>()
);
export const isLoadingFailure = createAction(
  '[Shared] Loading Failure',
  props<{ error: string }>()
);
