import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { contactusParams } from 'src/app/shared/models/contactus.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactusService {

  constructor(private http: HttpClient) { }
  contactUs(payload: contactusParams) {
    return this.http.post<any>(
      `${environment.apiUrl}contactUs`,
      payload
    );
  }
}
