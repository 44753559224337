import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ComposeEmailFrom,
  composeParams
} from 'src/app/shared/models/compose.model';
import { mailParams, mailResponse } from 'src/app/shared/models/mails.model';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { EncrDecrService } from 'src/app/util/encr-decr.service';
import { passcodeParams, resetCodeParams } from 'src/app/shared/models/foolder';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public encryptInfo;
    public decryptedInfo;
  key: string;
  constructor(private http: HttpClient) {
    this.key = environment.accessKey;
  }

  // getIMapFolders() {
  //   return this.http.get<imapFoldersModel[]>(
  //     `${environment.apiUrl}/getIMAPFolders`
  //   );
  // }
 
  getCountries(){
    return this.http.get(`${environment.apiUrl}getAllCountries`);
  }
  getEmails(payload: mailParams) {
    return this.http.post<mailResponse>(
      `${environment.apiUrl}/email/getEmails`,
      payload
    );
  }
  composeMail(payload: composeParams) {
    this.encryptInfo = CryptoJS.AES.encrypt(JSON.stringify(
      payload
    ), this.key).toString();

    // console.log(this.encryptInfo);
    this.encryptInfo = CryptoJS.AES.encrypt(JSON.stringify(payload), this.key).toString();
    
 
     return this.http.post<any>(`${environment.apiUrl}sendEmail`, this.encryptInfo);
    // return this.http.post<any>(`${environment.apiUrl}/sendEmail`, payload);
  }

  readEmail(payload: any) {
    return this.http.post<ComposeEmailFrom>(
      `${environment.apiUrl}/email/readMail`,
      // `${environment.apiUrl}/mongo/readMail`,
      payload
    );
  }
  deleteEmail(payload: any) {
    return this.http.post<any>(environment.apiUrl + '/deleteMail', payload);
  }
  moveEmail(payload: any) {
    return this.http.post<any>(environment.apiUrl + '/moveMail', payload);
  }
  markAsReadEmail(payload: any) {
    return this.http.post<any>(environment.apiUrl + '/markAsRead', payload);
  }
  markAsUnReadEmail(payload: any) {
    return this.http.post<any>(environment.apiUrl + '/markAsRead', payload);
  }
  getCallCode(payload: any){
    let countryparms={}
   
    countryparms['countryCode']=payload
    return this.http.post<any>(environment.apiUrl + 'getCallCode', countryparms);
  }
  encrypt(data:any){
   
  //   this.encryptInfo = CryptoJS.AES.encrypt(JSON.stringify(data), this.key).toString();
  //   console.log(this.encryptInfo);

  // var deData = CryptoJS.AES.decrypt(this.encryptInfo, this.key);
  //    console.log(JSON.parse(deData.toString(CryptoJS.enc.Utf8)))
  //   this.decryptedInfo = JSON.parse(deData.toString(CryptoJS.enc.Utf8));
  //    console.log("decryptedInfo");
  //    console.log(this.decryptedInfo);
    return data;
 
   return this.encryptInfo;
  }
  getpassCode(payload: passcodeParams){
    this.encryptInfo = CryptoJS.AES.encrypt(JSON.stringify(payload), this.key).toString();    
    return this.http.post<any>(environment.apiUrl + 'folderAuthentication',this.encryptInfo);
  }
  getresetPasscode(payload: resetCodeParams){
    this.encryptInfo = CryptoJS.AES.encrypt(JSON.stringify(payload), this.key).toString();    
    return this.http.put<any>(environment.apiUrl + 'resetFolderPassword',this.encryptInfo);
  }
  public drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    console.log(event.container.data);
    console.log(event.previousContainer.data);
  }
}
