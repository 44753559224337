<div class="sign-fm">
  <div class="logo">
    <a routerLink="/" class="logo"><img src="/assets/images/fm-logo.svg" routerLink="/" alt="O-Mail" /></a>
  </div>
  <div class="form-header">
    <h3>Enter the OTP</h3>
  </div>
  <form>
    <div class="row">
      <div class="col-md-12">
        <div>
          <mat-label>OTP<span class="asterick">*</span></mat-label>
          <mat-form-field appearance="outline" class="w-100">
            <input matInput #input placeholder="Enter the OTP">
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="otp-time-block">
      <div class="redirect-link">
        Don’t received OTP?<a href="#"> Resend OTP </a>
      </div>
      <div class="timeout-text">
        00:90
      </div>
    </div>
    <div class="form-btn">
      <button type="submit" class="btn-block">Login</button>
    </div>
  </form>
  <div class="redirect-link">
    Have you an account? <a routerLink="/sign-in">Login</a>
  </div>
  <div class="redirect-link">
    Don’t have an account? <a routerLink="/sign-up">Register</a>
  </div>
</div>
