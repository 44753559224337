import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { AuthService } from 'src/app/core/http/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ExceptionMessage } from 'src/app/shared/models/exception-message.model';
import { Alternateemail } from 'src/app/shared/validators/alternateemail';
import { CheckUserName } from 'src/app/shared/validators/check-user-name';
import { AppState } from 'src/app/store';
import { isLoading } from 'src/app/store/loader/loader.actions';
import { snackBarOpen } from 'src/app/store/snack-bar/snack-bar.actions';
import { environment } from 'src/environments/environment';
import { MustMatch } from '../_helpers/must-match.validator';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  exceptionMessage: ExceptionMessage = new ExceptionMessage();
  show: boolean;
  hide = true;
  hide1 = true;
  countryList: any;
  maxDate = moment().subtract(15, 'years').format('YYYY-MM-DD');
  Memorable: boolean = false;
  isActive: boolean = false;
  otp: boolean = false;
  memorable: boolean = true;
  // otp: boolean = true;
  Active: boolean = false;
  domainPath: any;
  countryZoneList: any = [];
  selectedCallcode: any
  constructor(
    private modalService: NgbModal,
    private store: Store<AppState>,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private commonService: CommonService,
    private router: Router,
    private checkUserName: CheckUserName,
    private checkalternateemail: Alternateemail,

  ) {
    this.getCountriesList();
    this.domainPath = environment.domainPath;
    this.show = false;
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z ]*$'), Validators.minLength(2), Validators.maxLength(26)]),],
      // '', [Validators.required, Validators.minLength(5), Validators.maxLength(50)]
      lastName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z ]*$'),
          Validators.minLength(2), Validators.maxLength(26)
        ]),
      ],
      userName: [
        '',
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(32),
          //  Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=.*[$@$!%*?&])(?=).{6,30}$/)

        ],
        [this.checkUserName.validate],
      ],
      contactNumber: [
        '', [Validators.required, Validators.pattern("^[0-9]*$")]
      ],
      countryId: [
        '', [Validators.required]
      ],
      recoveryEmailId: [
        '', [Validators.required, Validators.minLength(4),Validators.email,
        Validators.maxLength(32),], [this.checkalternateemail.validate],

      ],
      radioStatus: ['', Validators.required],
      memorableWord: [{ value: '', disabled: true }, [Validators.required, Validators.maxLength(20), Validators.minLength(8), Validators.pattern(/^[a-z]+$/i)]],
      hintfroMemorableWord: [{ value: '', disabled: true }, [Validators.required, Validators.maxLength(20), Validators.minLength(8), Validators.pattern(/^[a-z]+$/i)]],
      password: ['', [Validators.required, Validators.maxLength(16), Validators.minLength(8), Validators.pattern('(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!#^~%*?&,.<>"\'\\;:\{\\\}\\\[\\\]\\\|\\\+\\\-\\\=\\\_\\\)\\\(\\\)\\\`\\\/\\\\\\]])[A-Za-z0-9\d$@].{7,15}')]],
      confirmPassword: ['', Validators.required],
      callCode: ['']
    },
      {
        validator: MustMatch('password', 'confirmPassword'),
      }
    );
    this.registerForm.controls.radioStatus.valueChanges.subscribe(
      (value) => {
        if (value === 'memorableword') {
          this.registerForm.get('memorableWord')?.enable();
          this.registerForm.get('hintfroMemorableWord')?.enable();
        } else {
          this.registerForm.get('memorableWord')?.disable();
          this.registerForm.get('hintfroMemorableWord')?.disable();
          this.registerForm.controls['memorableWord'].reset();
          this.registerForm.controls['hintfroMemorableWord'].reset();
        }
      }
    );
  }

  ngOnInit(): void {

  }
  getCountriesList() {
    this.commonService.getCountries().subscribe((res: any) => {
      this.countryList = res.data;
    });
  }
  getcode(event?: any, countryId?: any) {
    this.countryZoneList = [];
    let selectedCountry = countryId === undefined ? this.countryList[event.target["selectedIndex"] - 1] : '';
    this.selectedCallcode = selectedCountry.callcode;
    this.countryZoneList.push(selectedCountry);
    this.registerForm.controls["callCode"].patchValue(this.selectedCallcode)
  }
  get f() {
    return this.registerForm.controls;
  }
  get password() {
    return this.registerForm.get('password');
  }


  onSubmit(formdata: any) {
    this.submitted = true;
    if (this.registerForm.valid) {
      // delete this.registerForm.value.acceptTerms;
      delete this.registerForm.value.confirmPassword;

      if (this.registerForm.controls['radioStatus'].value == "memorableword") {
        formdata['isMemorable'] = true;
        formdata['isOtp'] = false;
      } else {
        formdata['isOtp'] = true;
        formdata['isMemorable'] = false;
      }

      this.store.dispatch(isLoading({ isLoading: true }));
      this.authService.signup(formdata).subscribe((res: any) => {
        if (res.status.toLowerCase() == 'success') {
          this.store.dispatch(isLoading({ isLoading: false }));
          const msg = res.message;
          this.store.dispatch(
            snackBarOpen({
              message: msg,
              config: {
                horizontalPosition: 'end',
                verticalPosition: 'top',
                panelClass: ['snack-bar-success'],
              },
            })
          );
          // this.router.navigate(['sign-in']);
          this.router.navigateByUrl('/sign-in');
        }
        else {
          this.store.dispatch(isLoading({ isLoading: false }));
          const msg = 'User register unsuccessfully';
          this.store.dispatch(
            snackBarOpen({
              message: msg,
              config: {
                horizontalPosition: 'end',
                verticalPosition: 'top',
                panelClass: ['snack-bar-failure'],
              },
            })
          );
        }
      },


      );
    }
  }

  reginfo: any;
  closeResult: any;
  open(reginfo:any) {
    this.modalService.open(reginfo, { centered: true,backdrop: 'static',keyboard: false, modalDialogClass: 'reginfo-modal' });
  }

//   onMouseEnter(event?: any,modal?:any): void {
//     console.log(event)
//      event.target.click();
//  }
//  getRelatedElement(event:any,reginfo:any) { 
//   this.modalService.open(reginfo, { centered: true,backdrop: 'static',keyboard: false, modalDialogClass: 'reginfo-modal' });
//   // modal.dismiss('Cross click')
// }
//  onmouseleave(event:any): void {
// //  event.target.disable = true;
//  }

}
