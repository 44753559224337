<div class="contactus-page">
  <section class="contactus-comp-1">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <h3>Contact US</h3>
          <p>Let us know if you are facing any issue or have any query. We will get back to you at the earliest.
            Do share your thoughts with us as well and let us know how you feel about <strong>ONPASSIVE</strong></p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 order-2 order-md-1">
          <h3>Drop us a line!</h3>
          <form [formGroup]="contactusForm" (ngSubmit)="contactUs()" class="contactusform">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>First Name</label>
                  <input type="text" placeholder="First Name" formControlName="firstName" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                  <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                    <div *ngIf="f.firstName.errors.required">First Name is required</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Last Name</label>
                  <input type="text" placeholder="Last Name" formControlName="lastName" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                  <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                    <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Email Address</label>
                  <input type="text" placeholder="Email Address" formControlName="email" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                    <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group number">
                  <label>Phone Number</label>
                  <input type="text" ng2TelInput formControlName="phoneNumber" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.phoneNumber.errors }" />
                  <div *ngIf="submitted && f.phoneNumber.errors" class="invalid-feedback">
                    <div *ngIf="f.phoneNumber.errors.required">Mobile Number is required</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <textarea placeholder="Your Message" type="text" formControlName="message"
                  [ngClass]="{ 'is-invalid': submitted && f.message.errors }" rows="6" class="mb-0"></textarea>
                <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                  <div *ngIf="f.message.errors.required">Text is required</div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <button type="submit" class="send-button">Send Message</button>
            </div>
          </form>
        </div>
        <div class="col-lg-6 order-1 order-md-2">
          <img src="/assets/images/contact-us.png" class="img-fluid contact-us-img" alt="contact-us" />
        </div>
      </div>
    </div>
  </section>
  <section class="contactus-comp-2">
    <div class="container">
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <p>Let us know if you are facing any issue or have any query. We will get back to you at the earliest. Do
            share your
            thoughts with us as well and let us know how you feel about <strong>ONPASSIVE.</strong></p>
          <span>Write to us <a href="#">support@onpassive.com</a></span>
        </div>
      </div>
    </div>
  </section>
  <section class="contactus-comp-3">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="content-wrapper">
            <h3>US Address</h3>
            <p>7380 W Sand Lake Rd, Suite 500-529 Orlando,<br> FL 32819</p>
            <div class="map-box">
              <div class="location">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.008133719687!2d-81.48371448495283!3d28.44917119910696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e77fa88d5da425%3A0xcc4fc4c0d90db4bf!2s7380%20W%20Sand%20Lake%20Rd%20Suite%20500-529%2C%20Orlando%2C%20FL%2032819%2C%20USA!5e0!3m2!1sen!2sin!4v1622047263878!5m2!1sen!2sin"
                  allowfullscreen="" loading="lazy"></iframe>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="content-wrapper">
            <h3>Indian Address</h3>
            <p>DSR Inspire HUDA Techno Enclave, HITEC City, <br> Hyderabad, Telangana 500081</p>
            <div class="map-box">
              <div class="location">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.279447774689!2d78.38232141488766!3d17.44633400569439!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9113c690d5dd%3A0xc2e9a4f3c0411be1!2sDSR%20Inspire!5e0!3m2!1sen!2sin!4v1622047433423!5m2!1sen!2sin"
                  allowfullscreen="" loading="lazy"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
