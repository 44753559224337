import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from 'src/app/store';
import { getToken } from 'src/app/store/auth/auth.selectors';
import { snackBarOpen } from 'src/app/store/snack-bar/snack-bar.actions';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store$.pipe(
      select(getToken),
      map((token) => {
        if (!token) {
          // this.store$.dispatch(
          //   snackBarOpen({ message: 'Please sign in to continue...' })
          // );
          this._router.navigate(['sign-in']);
          return false;
        }
        return true;
      })
    );
  }
  constructor(private store$: Store<AppState>, private _router: Router) {}
}
