import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap, delay } from 'rxjs/operators';

import * as SnackBarActions from './snack-bar.actions';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class SnackBarEffects {
  closeSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SnackBarActions.snackBarClose),
        tap(() => this.matSnackBar.dismiss())
      ),
    { dispatch: false }
  );

  showSnackbar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SnackBarActions.snackBarOpen),
      map((action) => action),
      tap(({ message, action, config }) => {
        action = action ? action : 'X';
        config = config
          ? config
          : {
              horizontalPosition: 'end',
              verticalPosition: 'top',
              panelClass: ['snack-bar-success'],
            };
        this.matSnackBar.open(message, action, config);
      }),
      delay(3000),
      map(() => {
        return SnackBarActions.snackBarClose();
      })
    )
  );

  constructor(private actions$: Actions, private matSnackBar: MatSnackBar) {}
}
