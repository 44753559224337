import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class CommonApiService {
  apiUrl=environment.apiUrl;
  constructor( private _http:HttpClient) { }
  options?: {
    headers?: HttpHeaders | {[header: string]: string | string[]},
    observe?: 'body' | 'events' | 'response',
    params?: HttpParams|{[param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>},
    reportProgress?: boolean,
    responseType?: 'arraybuffer'|'blob'|'json'|'text',
    withCredentials?: boolean,
  }
  commonMethod(url:string, data?:any, method?:string,options?:any, Headers?:any, url_type?: any):Observable<any>{
    const endPoint = url_type ? `${url_type}${url}` : `${this.apiUrl}${url}`;
    const body = data ||  '';
    const reqOptions =options  || '';
    const headers = new HttpHeaders({
      'Content-Type':'application/json;',
      
    });

  const requestOptions = { headers: headers };

    // const httpOptions = headers
    switch (method) {
      case 'POST':
        return this._http.post(endPoint, body, requestOptions);
      case 'PUT':
        return this._http.put(endPoint, body, requestOptions);
      case 'DELETE':
        return this._http.delete(endPoint, body);
      default:
        return this._http.get(endPoint, body);
    }
  }

}
