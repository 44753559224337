import { createAction, props } from '@ngrx/store';
import { AppState } from '..';

export const syncState = createAction('[Shared] Sync App State');

export const syncStateSuccess = createAction(
  '[Shared] Sync App State Success',
  props<{ state: AppState }>()
);

export const syncStateFailure = createAction('[Shared] Sync App State Failure');
