import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SubscribeService } from 'src/app/core/http/subscribe.service';
import { AppState } from 'src/app/store';
import { isLoading } from 'src/app/store/loader/loader.actions';
import { snackBarOpen } from 'src/app/store/snack-bar/snack-bar.actions';
import { ExceptionMessage } from '../models/exception-message.model';

@Component({
  selector: 'app-home-footer',
  templateUrl: './home-footer.component.html',
  styleUrls: ['./home-footer.component.scss']
})
export class HomeFooterComponent implements OnInit {
  footerForm: FormGroup;
  submitted = false;



  exceptionMessage: ExceptionMessage = new ExceptionMessage();
  filteredOptions: any;
  enteredmail: any;
  val: any;
  constructor(private formBuilder: FormBuilder, 
    private store: Store<AppState>,
    private router: Router,
    private subscribeService: SubscribeService,) {


    this.footerForm = this.formBuilder.group(
      {
        email: ['', Validators.required]
      })
  }

  ngOnInit(): void {

  }

  onSubmit(formdata: any) {

    this.submitted = true;
   
    if (this.footerForm.invalid) {
      return;
    }
    else {
      console.log(this.footerForm.value);
      this.subscribeMail;
      // this.exceptionMessage = const data;
      const msg = 'Thanks For Subscribing with Omail';
      this.refresh();
      this.store.dispatch(
        snackBarOpen({
          message: msg,
          config: {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            panelClass: ['snack-bar-success'],
          },
        })
      );
    }



  }
  subscribeMail(formdata: any) {
    this.submitted = true;
    
    if (this.footerForm.invalid) {
      return;
    }
    else {
      console.log(this.footerForm.value);
      
      // this.exceptionMessage = const data;
      const msg = 'Thanks For Subscribing with Omail';
      this.refresh();
      this.store.dispatch(
        snackBarOpen({
          message: msg,
          config: {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            panelClass: ['snack-bar-success'],
          },
        })
        );
      }
      const val = this.footerForm.value;
    console.log(this.footerForm.value);
    

    this.subscribeService.subscribeMail(val).subscribe((res) => {
      console.log(res)
      this.val = res;
    });
  }
  refresh() {
    let currentUrl = decodeURIComponent(this.router.url);
    // console.log(currentUrl);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }


  get f() {
    return this.footerForm.controls;
  }
  gotoContactUsPage() {
    console.log('hai');

  }
  gotofax() {
    console.log('hai');
  }
}
