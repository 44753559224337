import { Action, createReducer, on } from '@ngrx/store';
import * as LoaderActions from './loader.actions';

export const loaderFeatureKey = 'loader';

export interface isLoadingState {
  isLoading: boolean;
}

export const initialState: isLoadingState = {
  isLoading: false,
};

export const isLoadingReducer = createReducer(
  initialState,

  on(LoaderActions.isLoading, (state, { isLoading }) => ({
    ...state,
    isLoading: isLoading,
  }))
);
