import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';
import { AppState } from 'src/app/store';
import { logout } from 'src/app/store/auth/auth.actions';
import { getToken } from 'src/app/store/auth/auth.selectors';
import { environment } from 'src/environments/environment';
import jwt_decode from "jwt-decode";
import { AuthService } from '../http/auth.service';
@Injectable()
export class HttpInterceptorInterceptor implements HttpInterceptor {
  constructor(
    private store$: Store<AppState>,
    private jwtHelper: JwtHelperService,
    private _router: Router,
    private dialogRef: MatDialog,
  ) { }

  /**
   * Intercepts all HTTP requests and adds the JWT token to the request's header if the URL
   * is a REST endpoint and not login or logout.
   */
  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Consider only adding the auth header to API requests as this will add it to all HTTP requests.
    return this.addToken(request).pipe(
      first(),
      mergeMap((requestWithToken: HttpRequest<any>) =>
        next.handle(requestWithToken)
      )
    );
  }
  /**
   * Adds the JWT token to the request's header.
   */
  private addToken(request: HttpRequest<any>): Observable<HttpRequest<any>> {
    // NOTE: DO NOT try to immediately setup this selector in the constructor or as an assignment in a
    // class member variable as there's no stores available when this interceptor fires fires up and
    // as a result it'll throw a runtime error.
    return this.store$.pipe(
      select(getToken),
      first(),
      mergeMap((token: string) => {
        if (token) {
          const isTokenExpired = this.jwtHelper.isTokenExpired(token);
          const decodedToken: any = jwt_decode(token);
          // console.log(decodedToken);
          if (isTokenExpired) {
            if(decodedToken.AutoLogin){
              this._router.navigate([`/omail/${decodedToken.sub}`]);
            }else{
              this.dialogRef.closeAll();
              this.store$.dispatch(
                logout({
                  message: 'Session time out please login to continue.......',
                })
              );
              this._router.navigate(['/sign-in']);
            }
          }
          request = request.clone({
            headers: request.headers
              .set('Authorization', `Bearer ${token}`)
              .set('Access-Control-Allow-Origin', environment.apiUrl),
            withCredentials: true,
          });
        } else {
          console.warn(`Invalid token!!! Cannot use token "${token}".`);
        }
        return of(request);
      })
    );
    //return of(request);
  }
}
