import { createAction, props } from '@ngrx/store';

export const login = createAction(
  '[Auth] Login',
  props<{ email: string; password: string;isMemorable:boolean;isOtp:boolean;length:any,}>()
);
export const memorable = createAction(
  '[Auth] memorable',
  props<{email:string;isMemorable: boolean;otp:any;keys: any;length:any ;values:string}>()
);
export const validateEmail = createAction(
  '[Auth] validateEmail',
  props<{ email:string;isMemorable: boolean;otp:any;keys: any;length:any ;values:string,role:any}>()
);

export const loginSuccess = createAction(
  '[Auth] Login Success',
  props<{ access_jwtToken: string }>()
);
export const otpSuccess = createAction(
  '[Auth] Otp Success',
  props<{ access_jwtToken: string }>()
);
export const autoLogin = createAction(
  '[Auth] Auto Login',
  props<{ userName: string; encrypted: any }>()
);
export const autologinSuccess = createAction(
  '[Auth] Login Success',
  props<{ access_jwtToken: string }>()
);
// export const otpFailure = createAction(
//   '[Auth] validateEmail Failure',
//   props<{ error: string }>()
// );
export const loginFailure = createAction(
  '[Auth] Login Failure',
  props<{ error: string }>()
);
export const otpFailure = createAction(
  '[Auth] Otp Failure',
  props<{ error: string }>()
);
export const memorableFailure = createAction(
  '[Auth] Memorable Failure',
  props<{ error: string }>()
);



export const autologinFailure = createAction(
  '[Auth] Auto Login Failure',
  props<{ error: string }>()
);
export const logout = createAction(
  '[Auth] Logout',
  props<{ message: string }>()
);




export function catchError(arg0: { message: string; }): any {
  throw new Error('Function not implemented.');
}

