<section class="site-footer">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-lg-4">
        <div class="f-logo">
          <a routerLink="/"><img src="/assets/images/footer-logo.svg" routerLink="/" alt="footer-logo" /></a>
        </div>
        <p>
          O-Mail is a beautifully designed, and<br> universal Webmail for business.
        </p>
      </div>
      <div class="col-md-6 col-lg-3">
        <h6>Useful links</h6>
        <ul>
          <li>
            <a routerLink="/about-us">About us</a>
          </li>
          <li>
            <a routerLink="/privacy-policy">Privacy Policy</a>
          </li>
          <li>
            <a routerLink="/faqs">Faq</a>
          </li>
          <li>
            <a routerLink="/why-omail" class="anchor_tag text-white">Why O-Mail</a>
          </li>
        </ul>
      </div>
      <div class="col-md-6 col-lg-3">
        <h6>Privacy</h6>
        <ul>
          <li>
            <a routerLink="/terms-and-conditions">Terms and conditions</a>
          </li>
          <li>
            <a routerLink="/contact-us">Contact-us</a>
          </li>
          <li>
            <a routerLink="/feature" class="anchor_tag text-white">Features</a>
          </li>
        </ul>
      </div>
      <div class="col-md-6 col-lg-2">
        <div class="address-info-block">
          <h6>Contact Info</h6>
          <p>
            7380 W Sand Lake Rd, <br>
            Suite 500-529, Orlando, <br>
            FL 32819. USA
          </p>
          <h6>IT Campus</h6>
          <p>DSR Inspire, Plot No: 21, Sector: 1, <br>
            Hitech City Rd, HUDA Techno Enclave,<br> Madhapur, Telangana 500081</p>
        </div>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-md-12 col-lg-4">
        <div class="social-icons">
          <div>
            <h4>Follow us:</h4>
          </div>
          <div>
            <ul>
              <li>
                <a href="https://www.facebook.com/OnpassiveOmail-100461678732860" target="blank">
                  <img src="/assets/images/facebook.svg" alt="facebook" />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/onpassiveomail" target="blank">
                  <img src="/assets/images/instagram.svg" alt="instagram" />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/ONPASSIVE" target="blank">
                  <img src="/assets/images/twitter.svg" alt="twitter" />
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/onpassiveomail" target="blank">
                  <img src="/assets/images/linkedin.svg" alt="linkedin" />
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/c/ONPASSIVE" target="blank">
                  <img src="/assets/images/youtube.svg" alt="youtube" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-5">
        <form class="news-letter" [formGroup]="footerForm" (ngSubmit)="subscribeMail(footerForm.value)" autocomplete="off">
          <div class="input-group">
            <input type="text" id="email" class="form-control bg-transparent text-white" name="email"
              placeholder="Enter your E-mail Address"  formControlName="email"
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />  
              <button class="btn subscribe" type="submit" id="button-addon2">
                SUBSCRIBE
              </button>         
            <!-- <div class="input-group-append">
              <button class="btn subscribe" type="submit" id="button-addon2">
                SUBSCRIBE
              </button>
            </div> -->
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is required</div>
            </div>
          </div>          
        </form>
      </div>
    </div>
  </div>
</section>
<section class="site-copywrite">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-6">
        © 2021 All rights reserved | Terms and Conditions
      </div>
      <div class="col-12 col-md-6">
        <div class="text-md-end text-center">Powered by : <a href="https://onpassive.com/"
            target="_blank">www.onpassive.com</a>
        </div>
      </div>
    </div>
  </div>
</section>
