<div class="sign-fm">
  <div class="logo">
    <a routerLink="/" class="logo"><img src="/assets/images/fm-logo.svg" routerLink="/" alt="O-Mail" /></a>
  </div>
  <div class="form-header">
    <h3>Forgot Login Details</h3>
  </div>
  <form [formGroup]="forgotPasswordForm" (ngSubmit)="forGotPassword()">
    <div class="fld-option-details">
      <div class="fld-option-list">
        <div class="fld-option-text">Remind User Name</div>
        <div class="forgot-switch">
          <mat-slide-toggle (change)="onChanges()" formControlName="userName"></mat-slide-toggle>
        </div>
      </div>
      <div class="fld-option-list">
        <div class="fld-option-text">Forgot Password</div>
        <div class="forgot-switch">
          <mat-slide-toggle (change)="onChanges()" formControlName="password"></mat-slide-toggle>
        </div>
      </div>
      <div class="fld-option-list" >
        <div class="fld-option-text">Memorable Word</div>
        <div class="forgot-switch">
          <mat-slide-toggle (change)="onChanges()"  formControlName="memorableWord"></mat-slide-toggle>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="omail-form-field">
          <mat-label>User Email ID<span class="asterick">*</span></mat-label>
          <mat-form-field appearance="outline" class="w-100">
            <input matInput placeholder="Enter User Email ID" formControlName="recoveredEmail" required type="email"
            (keyup)="onChanges()"
              aria-describedby="emailHelp">
            <mat-icon matSuffix>person_outline</mat-icon>
          </mat-form-field>
          <mat-error *ngIf="(submitted || f.recoveredEmail.touched)&& f.recoveredEmail.errors" class="invalid-feedback">
            <div *ngIf="f.recoveredEmail.errors.required">
              User Email ID is required
            </div>
            <div *ngIf="f.recoveredEmail.errors.email">Please enter valid Email Id.</div>
          </mat-error>
        </div>
      </div>
    </div>
    <div class="form-btn">
      <button  [disabled]="forgotMatslide"  type="submit" class="btn-block">Submit</button>
    </div>
  </form>
  <div class="redirect-link">
    Have you an account? <a routerLink="/sign-in">Login</a>
  </div>
  <div class="redirect-link">
    Don’t have an account? <a routerLink="/sign-up">Register</a>
  </div>
</div>
