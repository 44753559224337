import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap, switchMap, mergeMap } from 'rxjs/operators';
import { from, of } from 'rxjs';

import * as AuthActions from './auth.actions';
import { snackBarOpen } from '../snack-bar/snack-bar.actions';
import { AuthService } from 'src/app/core/http/auth.service';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { AppState } from '..';
import { isLoading } from '../loader/loader.actions';
import { getUser } from '../settings/settings.actions';

@Injectable()
export class AuthEffects {
  roleData!: string;
  // role!: string;
  
  constructor(
    private authService: AuthService,
    private store: Store<AppState>,
    private actions$: Actions,
    private _router: Router
    // this.roleData = sessionStorage.getItem('role');
  ) {   }
  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.login),
      concatMap(({ email, password, isMemorable, isOtp, length }) =>
        /** An EMPTY observable only emits completion. Replace with your own observable API request */ {
        this.store.dispatch(isLoading({ isLoading: true }));
        return this.authService.login({ email, password, isMemorable, isOtp, length}).pipe(
          map(({ token }) => token),
          map((accessToken) => {
            this.store.dispatch(isLoading({ isLoading: false }));
            this.store.dispatch(
              snackBarOpen({ message: 'Successfully sent OTP' })
            );
            console.log(accessToken);
            
            return AuthActions.otpSuccess({ access_jwtToken: accessToken });
            
          }),
          // catchError((error) => {
          //   this.store.dispatch(isLoading({ isLoading: false }));
          //   return of(
          //     // AuthActions.loginFailure({ error: error?.error?.message })
          //       AuthActions.loginFailure({ error: "Please enter valid credentials" })
          //   );
          // })
        );
      }
      )
    );
  });


  validateEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.validateEmail),
      switchMap(({ email, isMemorable, otp, keys, length, values,role }) =>
        /** An EMPTY observable only emits completion. Replace with your own observable API request */ {
        this.store.dispatch(isLoading({ isLoading: true }));
        return this.authService.validateEmail({ email, isMemorable, otp, keys, length, values,role}).pipe(
          // return this.authService.validateCheck({ email}).pipe(
            map(resp => resp),
          // map(({ jwtToken }) => jwtToken,({response}) => response),
          map((resp) => {
            this.roleData=''
           console.log(resp.message)
            if ((resp.jwtToken != '')) {
              this.store.dispatch(isLoading({ isLoading: false }));
             this.roleData=role
              this.store.dispatch(
                // snackBarOpen({ message:'Successfully logged in' })

                snackBarOpen({ message: resp.message})
               

              );
              return AuthActions.otpSuccess({ access_jwtToken: resp.jwtToken });
             
            }
            else if ((resp.jwtToken == '' || resp.jwtToken == null || resp.jwtToken == undefined) && isMemorable) {
              this.store.dispatch(isLoading({ isLoading: false }));
              // const error = resp.message +' '+ resp.response? resp.response[0]:'';
              
              const error = resp.response.length > 0 ? resp.message +' '+resp.response[0]: resp.message;

              this.store.dispatch(
                snackBarOpen({
                  message: error,
                  config: {
                    horizontalPosition: 'end',
                    verticalPosition: 'top',
                    panelClass: ['snack-bar-failure'],
                  },
                })
              );
              // this.store.dispatch(
              //   snackBarOpen({ message: 'word is not matched' })

              // );
              // return AuthActions.otpSuccess({ access_jwtToken: jwtToken });
            }
            else {
              this.store.dispatch(isLoading({ isLoading: false }));
              // if(!isMemorable){

              // this.store.dispatch(
              // snackBarOpen({ message: 'Otp Is not matched' })
              const error = 'Otp Is not matched';
              this.store.dispatch(
                snackBarOpen({
                  message: error,
                  config: {
                    horizontalPosition: 'end',
                    verticalPosition: 'top',
                    panelClass: ['snack-bar-failure'],
                  },
                })
              );

              // );
            }

          }),

          catchError((error) => {


            return of(
              AuthActions.otpFailure({ error: error?.error?.message })
            );


          }),
          switchMap(values => values instanceof Promise ? from(values) : from([values])
          )

        );
      }
      )
    );


  });
 
  otpSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.otpSuccess),
        tap(() => {
           this.store.dispatch(getUser());
          if(this.roleData=='ADMIN'){
          this._router.navigate(['adminmain/adminhome']);
          }
          else{
            // this._router.navigate(['mail/INBOX']);
            return this._router.navigate(['mail/INBOX']);
          }
          //  
        })
      ),
    { dispatch: false }
  );


  otpFailure$ = createEffect(
    () =>
      this.actions$.pipe(

        ofType(AuthActions.otpFailure),
        tap(({ error }) => {
          error = error ? error : 'OTP is not matched.';
          this.store.dispatch(isLoading({ isLoading: false }));

          this.store.dispatch(
            snackBarOpen({
              message: error,
              config: {
                horizontalPosition: 'end',
                verticalPosition: 'top',
                panelClass: ['snack-bar-failure'],
              },
            })
          );
        })

      ),
    { dispatch: false }
  );
  memorableFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.memorableFailure),
        tap(({ error }) => {
          error = error ? error : 'please enter valid memorable.';


          this.store.dispatch(
            snackBarOpen({
              message: error,
              config: {
                horizontalPosition: 'end',
                verticalPosition: 'top',
                panelClass: ['snack-bar-failure'],
              },
            })
          );
        })
      ),
    { dispatch: false }
  );


  autologin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.autoLogin),
      // concatMap(({ email, password }) =>
        concatMap(({  userName,encrypted }) =>

        /** An EMPTY observable only emits completion. Replace with your own observable API request */ {
        this.store.dispatch(isLoading({ isLoading: true }));
        
        return this.authService.autologin({ userName, encrypted }).pipe(
          map(({ jwtToken }) => jwtToken),
          map((accessToken) => {
            this.store.dispatch(isLoading({ isLoading: false }));
            /* this.store.dispatch(
               snackBarOpen({ message: 'Successfully logged in' })
             );*/
            return AuthActions.otpSuccess({ access_jwtToken: accessToken });
          }),
          catchError((error) => {
            this.store.dispatch(isLoading({ isLoading: false }));
            return of(
              AuthActions.autologinFailure({ error: error?.error?.message })
            );
          })
        );
      }
      )
    );
  });
 
  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logout),
        tap(({ message }) => {
          // sessionStorage.clear();
         
              message = message ? message : 'Logout Successfully...';
              this.store.dispatch(snackBarOpen({ message: message }));
              this._router.navigate(['/sign-in']);
            

          
        })
      ),
    { dispatch: false }
  );

}
