import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {
  @ViewChild("imgWrap") imgWrap?: ElementRef;
  @ViewChild("formAnimate") formAnimate?: ElementRef;
  @ViewChild("animateTitle") animateTitle?: ElementRef;
  constructor(private renderer: Renderer2,) { }

  ngOnInit(): void {
    if (window.innerWidth > 1023) {
      setTimeout(() => {
        this.renderer.addClass(this.imgWrap?.nativeElement, "active");
        this.renderer.addClass(this.animateTitle?.nativeElement, "active");

      }, 4000);
      setTimeout(() => {
        this.renderer.addClass(this.formAnimate?.nativeElement, "active");
      }, 5000);
    }
  }

}
