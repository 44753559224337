import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/store';
import { loading } from 'src/app/store/loader/loader.selectors';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  isLoading$: Observable<boolean>;
  constructor(private store: Store<AppState>) {
    this.isLoading$ = this.store.pipe(select(loading));
  }

  ngOnInit(): void {}
}
