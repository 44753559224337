import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { AppState } from 'src/app/store';
import { Store } from '@ngrx/store';
import { snackBarOpen } from 'src/app/store/snack-bar/snack-bar.actions';
import { isLoading } from 'src/app/store/loader/loader.actions';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor( private readonly store: Store<AppState>,) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
    .pipe(catchError((error: HttpErrorResponse) => {
      console.log(error);
        let errorMsg = '';
        if (error.error instanceof ErrorEvent) {
          // console.log('this is client side error');
          // errorMsg = `Error: ${error.error.message}`;
        }
        else {
          // console.log('this is server side error');
          
          
          if(error.status == 500){
            errorMsg = `Internal Server Error`;
            this.store.dispatch(
              snackBarOpen({
                message: "Internal Server Error",
                config: {
                  horizontalPosition: 'end',
                  verticalPosition: 'top',
                  panelClass: ['snack-bar-failure'],
                },
              })
            );
          }
        //   if(error.status == 404){
        //     // errorMsg = `You are Not Registered, Please Register`;
        //     this.store.dispatch(
        //       snackBarOpen({
        //         message: errorMsg,
        //         config: {
        //           horizontalPosition: 'end',
        //           verticalPosition: 'top',
        //           panelClass: ['snack-bar-failure'],
        //         },
        //       })
        //     );
        //   }
        //   if(error.status == 401){
        //     // errorMsg = `Please Enter Correct password...`;
        //     this.store.dispatch(
        //       snackBarOpen({
        //         message: errorMsg,
        //         config: {
        //           horizontalPosition: 'end',
        //           verticalPosition: 'top',
        //           panelClass: ['snack-bar-failure'],
        //         },
        //       })
        //     );
        //   }
        }
        this.store.dispatch(isLoading({ isLoading: false }));
        return throwError(errorMsg);
      })
    );
  }
}
