import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { subscibeParams } from 'src/app/shared/models/subscribe.model';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { CommonService } from '../services/common.service';
@Injectable({
  providedIn: 'root'
})
export class SubscribeService {
  key!: any;
  public encryptInfo;
  public decryptedInfo;
  constructor(private http: HttpClient,private security :CommonService) { 
    this.key = environment.accessKey;
  }
  subscribeMail(value: subscibeParams) {
    
    let emailparams = {}
    emailparams['email'] = value
    this.encryptInfo = this.security.encrypt(emailparams)

  
  // this.encryptInfo = CryptoJS.AES.encrypt(JSON.stringify({formData}), this.key).toString();
 

 return this.http.post(`${environment.apiUrl}saveOrUpdateVacation`,this.encryptInfo,{})
    // return this.http.post<any>(
    //   `${environment.apiUrl}subscribeMail/${value.email}`, this.encryptInfo
    // );
  }
}


