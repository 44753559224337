import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// import { AuthRoutingModule } from './auth-routing.module';
import { BaseLayoutComponent } from 'src/app/shared/layouts/base-layout/base-layout.component';
import { AuthLayoutComponent } from 'src/app/shared/layouts/auth-layout/auth-layout.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import * as fromAuth from '../../store/auth/auth.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from '../../store/auth/auth.effects';
import * as fromSettings from '../../store/settings/settings.reducer';
import { SettingsEffects } from '../../store/settings/settings.effects';
import { HomeHeaderComponent } from 'src/app/shared/home-header/home-header.component';
import { HomeFooterComponent } from 'src/app/shared/home-footer/home-footer.component';
import { FeatureComponent } from './components/feature/feature.component';
import { WhyOmailComponent } from './components/why-omail/why-omail.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { AngularMaterialModule } from 'src/app/shared/modules/angular-material.module';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { AuthLayoutOneComponent } from 'src/app/shared/layouts/auth-layout-one/auth-layout-one.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { AlphabetOnlyDirective } from 'src/app/shared/directives/alphabet-only.directive';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { AutoLoginComponent } from './components/auto-login/auto-login.component';
import { SelectLanguageComponent } from 'src/app/shared/select-language/select-language.component';
import { CarouselModule } from 'ngx-owl-carousel-2';
import { OtpComponent } from './components/otp/otp.component';
import { MemorbleWordComponent } from './components/memorble-word/memorble-word.component';
import { AuthRoutingModule } from './auth-routing.module';
@NgModule({
  declarations: [
    AlphabetOnlyDirective,
    BaseLayoutComponent,
    AuthLayoutComponent,
    AuthLayoutOneComponent,
    HomeComponent,
    LoginComponent,
    SignUpComponent,
    HomeHeaderComponent,
    HomeFooterComponent,
    FeatureComponent,
    WhyOmailComponent,
    AboutUsComponent,
    FaqsComponent,
    ContactUsComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    AutoLoginComponent,
    SelectLanguageComponent,
    OtpComponent,
    MemorbleWordComponent,

  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    NgxCaptchaModule,
    ReactiveFormsModule,
    FormsModule,
    CarouselModule,
    AngularMaterialModule,
    PasswordStrengthMeterModule,
    StoreModule.forFeature(fromAuth.authFeatureKey, fromAuth.reducer),
    EffectsModule.forFeature([AuthEffects, SettingsEffects]),
    StoreModule.forFeature(
      fromSettings.settingsFeatureKey,
      fromSettings.reducer
    ),
  ],
})
export class AuthModule { }
