import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-2';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  //Owel Carousel homeone
  homesliderone: OwlOptions = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    nav: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      }
    },
  }
    //Owel Carousel hometwo
    homeslidertwo: OwlOptions = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 5000,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: false,
      nav: true,
      navSpeed: 1000,
      navText: ["<img src='../../../../../assets/images/h-s-t-pre.png' alt='h-s-t-pre' />", "<img src='../../../../../assets/images/h-s-t-next.png' alt='h-s-t-pre' />"],
      responsive: {
        0: {
          items: 1
        }
      },
    }

}
