import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromLoader from './loader.reducer';

export const selectLoaderState =
  createFeatureSelector<fromLoader.isLoadingState>(fromLoader.loaderFeatureKey);

export const loading = createSelector(
  selectLoaderState,
  (state:any) =>{ 
  return state?.isLoading
});




// const selectPosts = (state: any) => state.hasOwnProperty('posts') ? state.posts : '';

// export const selectedPosts = createSelector(
//   selectPosts,
//   (state: any) => {
//     return state.hasOwnProperty('posts') ? state.posts : '';
//   }
// );