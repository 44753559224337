import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/http/auth.service';
import { ExceptionMessage } from 'src/app/shared/models/exception-message.model';
import { AppState } from 'src/app/store';
import { login } from 'src/app/store/auth/auth.actions';
import { isLoading } from 'src/app/store/loader/loader.actions';
import { loading } from 'src/app/store/loader/loader.selectors';
import { snackBarOpen } from 'src/app/store/snack-bar/snack-bar.actions';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  show: boolean;
  forgotPasswordForm:any=FormGroup;
  submitted = false;
  loading$: Observable<boolean>;
  hide = true;

  exceptionMessage: ExceptionMessage = new ExceptionMessage();

  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService
  ) {
    this.loading$ = this.store.pipe(select(loading));
    this.show = false;
    
  }

  ngOnInit(): void {
    this.forgotPasswordForm = this.fb.group({
      userName:[{value:false, disabled:true}],
      password:[true],
      memorableWord:[{value:false, disabled:true}],
      recoveredEmail: ['', [Validators.required, Validators.email]],
    });
  }


  get f() {
    return this.forgotPasswordForm.controls;
  }

forgotMatslide:boolean=false;
  onChanges(){
    if(this.forgotPasswordForm.get('recoveredEmail').valid && this.forgotPasswordForm.get('recoveredEmail').value!=''){
       this.forgotPasswordForm.get('userName')?.enable() 
       this.forgotPasswordForm.get('memorableWord')?.enable()    
   }
   else{
    this.forgotPasswordForm.get('userName')?.disable()
    this.forgotPasswordForm.get('memorableWord')?.disable()
   }
   if(this.forgotPasswordForm.controls['userName'].value==true || 
   this.forgotPasswordForm.controls['password'].value==true || 
   this.forgotPasswordForm.controls['memorableWord'].value==true){
this.forgotMatslide=false
   }else{ this.forgotMatslide=true;}

   if(this.forgotPasswordForm.controls['userName'].value==false && 
   this.forgotPasswordForm.controls['password'].value==false &&
   this.forgotPasswordForm.controls['memorableWord'].value==false){
    const msg = 'please select atleast one';
    this.store.dispatch(
      snackBarOpen({
        message: msg,
        config: {
          horizontalPosition: 'end',
          verticalPosition: 'top',
          panelClass: ['snack-bar-failure'],
        },
      })
    );
   }
 }

  forGotPassword(){
    this.submitted = true;
    if (this.forgotPasswordForm.valid) {
      this.store.dispatch(isLoading({ isLoading: true }));
      this.authService.forgotPassword(this.forgotPasswordForm.value).subscribe((res) => {
        if ( res.status== 'Success') {
          this.store.dispatch(isLoading({ isLoading: false }));
          const msg = res.message;
          this.store.dispatch(
            snackBarOpen({
              message: msg,
              config: {
                horizontalPosition: 'end',
                verticalPosition: 'top',
                panelClass: ['snack-bar-success'],
              },
            })
          );
          this.router.navigateByUrl('/sign-in');
        } else {
          this.store.dispatch(isLoading({ isLoading: false }));
          res.status== 'Failed'
          const msg = res.message;
          this.store.dispatch(
            snackBarOpen({
              message: msg,
              config: {
                horizontalPosition: 'end',
                verticalPosition: 'top',
                panelClass: ['snack-bar-failure'],
              },
            })
          );
        }
      });
    }
  }
}
