import { Action, createReducer, on } from '@ngrx/store';
import * as SnackBarActions from './snack-bar.actions';

export const snackBarFeatureKey = 'snackBar';

export interface SnackBarState {
  show: boolean;
}

export const initialState: SnackBarState = {
  show: false,
};

export const snackBarReducer = createReducer(
  initialState,
  on(SnackBarActions.snackBarOpen, (state) => ({
    ...state,
    show: true,
  })),
  on(SnackBarActions.snackBarClose, (state) => ({
    ...state,
    show: false,
  }))
);
