import { Injectable } from '@angular/core';
import { AsyncValidator, FormControl } from '@angular/forms';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from 'src/app/core/http/auth.service';

@Injectable({
  providedIn: 'root',
})
export class Alternateemail implements AsyncValidator {
  validate = (control: FormControl) => {
    const { value } = control;
    console.log(value)
    return this.authService.checkPersonalEmail(value).pipe(
      map((value) => {
        console.log(value);
        
        if (value.message === 'EXISTS') {
          return { checkalternateemail: true };
        } else {
          return null;
        //   return { checkPersonalEmail: false };
        }
      }),
      catchError((err) => {
        console.log(err)
        return of({ checkalternateemail: false });
      })
    );
  };
  constructor(private authService: AuthService) {}
}
