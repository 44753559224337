import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss']
})
export class BaseLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  onActivate(event) {
    window.scroll(0,0); 
    window.scroll({ 
            top: 0, 
            left: 0, 
            behavior: 'smooth' 
     }); 
      document.body.scrollTop = 0;
     //or document.querySelector('body').scrollTo(0,0)    
 }

}
