import { Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';

export interface RouterStateUrl {
  path?: string;
  url: string;
  params: Params;
  queryParams: Params;
}

export class AppRouteSerializer
  implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }
    const {
      url,
      root: { queryParams },
    } = routerState;
    const { params } = route;
    // const currentFirstSegment = route.firstChild?.routeConfig?.path;
    const path = routerState.root.firstChild?.routeConfig?.path;

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return { path, url, params, queryParams };
  }
}
