import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-feature',
  templateUrl: './feature.component.html',
  styleUrls: ['./feature.component.scss']
})
export class FeatureComponent implements OnInit {

  constructor(config: NgbModalConfig, private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  open(messagetriage: any) {
    this.modalService.open(messagetriage, { centered: true,backdrop: 'static',keyboard: false, scrollable: true, modalDialogClass: 'feature-modals', size: 'xl' });
  }

  open1(emailorganization: any) {
    this.modalService.open(emailorganization, { centered: true,backdrop: 'static',keyboard: false, scrollable: true, modalDialogClass: 'feature-modals', size: 'xl' });
  }
  
  open2(emailmanagement: any) {
    this.modalService.open(emailmanagement, { centered: true,backdrop: 'static',keyboard: false, scrollable: true, modalDialogClass: 'feature-modals', size: 'xl' });
  }

  open3(composition: any) {
    this.modalService.open(composition, { centered: true,backdrop: 'static',keyboard: false, scrollable: true, modalDialogClass: 'feature-modals', size: 'xl' });
  }

  open4(search: any) {
    this.modalService.open(search, { centered: true,backdrop: 'static',keyboard: false, scrollable: true, modalDialogClass: 'feature-modals', size: 'xl' });
  }

  open5(userinterface: any) {
    this.modalService.open(userinterface, { centered: true,backdrop: 'static',keyboard: false, scrollable: true, modalDialogClass: 'feature-modals', size: 'xl' });
  }

  open6(safetysecurity: any) {
    this.modalService.open(safetysecurity, { centered: true,backdrop: 'static',keyboard: false, scrollable: true, modalDialogClass: 'feature-modals', size: 'xl' });
  }

  open7(systemfeatures: any) {
    this.modalService.open(systemfeatures, { centered: true,backdrop: 'static',keyboard: false, scrollable: true, modalDialogClass: 'feature-modals', size: 'xl' });
  }

  open8(general: any) {
    this.modalService.open(general, { centered: true,backdrop: 'static',keyboard: false, scrollable: true, modalDialogClass: 'feature-modals', size: 'xl' });
  }

}
