<div class="about-page">
  <section class="">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="inner-page-banner">
            <img src="/assets/images/about-us-img.png" class="img-fluid about-us-img" alt="about-us-img" />
          </div>
        </div>
        <div class="col-lg-6">
          <h3>About Us</h3>
          <p> O-Mail is a beautifully designed, and universal Webmail for business
            app capable of managing an unlimited number of mail accounts from various providers, allowing for smart push
            notifications and group emailing while enabling personalization across multiple email accounts.</p>
          <p> O-Mail comes with a wide range of visual and technical customizations, swipe actions and menus, action
            bars
            and buttons, colors, and themes. As a next-gen mailing tool, it delivers robust capabilities to your Team.
            O-Mail data centers have top-notch security and surveillance, with a reliable 99.9% uptime.</p>
          <p> O-Mail’s domain-based Email has an integrated calendar that automatically syncs your events and updates on
            the fly. Plan events, invite your contacts, and set reminders with unmatched ease. Setting your calendar has
            never been this easy allowing you to find the perfect balance in planning your daily and weekly schedule.
          </p>
        </div>
      </div>
    </div>
  </section>
</div>
