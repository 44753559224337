import { Action, createReducer, on } from '@ngrx/store';
import { user } from 'src/app/shared/models/profile.model';
import * as SettingsActions from './settings.actions';

export const settingsFeatureKey = 'settings';

export interface State {
  user?: user;
}

export const initialState: State = {};

export const reducer = createReducer(
  initialState,

  on(SettingsActions.getUser, (state) => state),
  on(SettingsActions.getUserSuccess, (state, { user }) => ({
    ...state,
    user: user,
  })),
  on(SettingsActions.getUserFailure, (state, action) => state)
);
