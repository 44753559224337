<div class="main-home-page">
  <section class="home-comp-one">
    <div class="container">
      <owl-carousel-o [options]="homesliderone">
        <ng-template carouselSlide>
          <div class="row align-items-center">
            <div class="col-md-6 order-2 order-md-1">
              <h3>Start Sending <span>Secure Emails!</span></h3>
              <p>From its medieval origins to the digital era, learn everything there is to know about the.</p>
            </div>
            <div class="col-md-6 order-1 order-md-2">
              <img src="../../../../../assets/images/home-slider-img-1.png" class="img-fluid" alt="home-slider-img-1" />
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="row align-items-center">
            <div class="col-md-6 order-2 order-md-1">
              <h3>Start Sending <span>Secure Emails!</span></h3>
              <p>From its medieval origins to the digital era, learn everything there is to know about the.</p>
            </div>
            <div class="col-md-6 order-1 order-md-2">
              <img src="../../../../../assets/images/home-slider-img-1.png" class="img-fluid" alt="home-slider-img-1" />
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="row align-items-center">
            <div class="col-md-6 order-2 order-md-1">
              <h3>Start Sending <span>Secure Emails!</span></h3>
              <p>From its medieval origins to the digital era, learn everything there is to know about the.</p>
            </div>
            <div class="col-md-6 order-1 order-md-2">
              <img src="../../../../../assets/images/home-slider-img-1.png" class="img-fluid" alt="home-slider-img-1" />
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </section>
  <section class="home-comp-two">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6 order-2 order-md-1">
          <div class="omail-video-link">
            <iframe height="320" src="https://www.youtube.com/embed/j9DmVOZcOhI?list=PL_QW7X7pW3c4BSloVFp4JycwYzM2cr72W"></iframe>            
          </div>     
        </div>
        <div class="col-md-6 order-1 order-md-2">
          <h2>Explore the Unique <span>Advantages of O-Mail</span></h2>
        </div>
      </div>
    </div>
  </section>
  <section class="home-comp-three">
    <div class="container">
      <owl-carousel-o [options]="homeslidertwo">
        <ng-template carouselSlide>
          <div class="row align-items-center">
            <div class="col-md-6 order-2 order-md-1">
              <h3>Start Sending <span>Secure Emails!</span></h3>
              <p>From its medieval origins to the digital era, learn everything there is to know about the.</p>
            </div>
            <div class="col-md-6 order-1 order-md-2">
              <img src="./../../../../../assets/images/woman-working.jpg" class="img-fluid" alt="home-site-img-1" />
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="row align-items-center">
            <div class="col-md-6 order-2 order-md-1">
              <h3>Start Sending <span>Secure Emails!</span></h3>
              <p>From its medieval origins to the digital era, learn everything there is to know about the.</p>
            </div>
            <div class="col-md-6 order-1 order-md-2">
              <img src="./../../../../../assets/images/woman-working.jpg" class="img-fluid" alt="home-site-img-1" />
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </section>
  <section class="home-comp-four">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6">
          <img src="./../../../../../assets/images/omail-laptop.png" class="img-fluid" alt="Video Frame" />
        </div>
        <div class="col-md-6 explore-text">
          <div>
            <h3>Explore outstanding <span>mix of features</span></h3>
            <h4>From its medieval origins to the digital era,<span>learn everything there is to know about the.</span>
            </h4>
            <div class="all-features">
              <div class="feature">
                <div class="feature-circle">
                  <img src="./../../../../../assets/images/email-feature.png" class="d-block img-fluid" alt="Email" />
                </div>
                <h6>Email</h6>
              </div>
              <div class="feature">
                <div class="feature-circle">
                  <img src="./../../../../../assets/images/calendar-feature.png" class="d-block img-fluid"
                    alt="Calendar" />
                </div>
                <h6>Calendar</h6>
              </div>
              <div class="feature">
                <div class="feature-circle">
                  <img src="./../../../../../assets/images/contacts-feature.png" class="d-block img-fluid"
                    alt="Contacts" />
                </div>
                <h6>Contacts</h6>
              </div>
              <div class="feature">
                <div class="feature-circle">
                  <img src="./../../../../../assets/images/notes-feature.png" class="d-block img-fluid" alt="Notes" />
                </div>
                <h6>Notes</h6>
              </div>
              <div class="feature">
                <div class="feature-circle">
                  <img src="./../../../../../assets/images/chat-feature.png" class="d-block img-fluid" alt="Chat" />
                </div>
                <h6>Chat</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="home-comp-five">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <h3>Master your workflow</h3>
          <p>Discover unique tools for increased productivity and better email management.</p>
        </div>
      </div>
      <div class="row flows">
        <div class="col-12 col-md-6 col-lg-3">
          <div class="flow">
            <div class="circle">
              <img src="./../../../../../assets/images/message-encryotion.svg" class="d-block img-fluid" alt="message-encryotion" />
            </div>
            <h5>Message Encryption</h5>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="flow flow2">
            <div class="circle">
              <img src="./../../../../../assets/images/incoming-emails.svg" class="d-block img-fluid" alt="incoming-emails" />
            </div>
            <h5>Snooze Incoming Emails</h5>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="flow flow3">
            <div class="circle">
              <img src="./../../../../../assets/images/replies.svg" class="d-block img-fluid" alt="replies" />
            </div>
            <h5>Watch for Replies</h5>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="flow flow4">
            <div class="circle">
              <img src="./../../../../../assets/images/message-translation.svg" class="d-block img-fluid" alt="message-translation" />
            </div>
            <h5>Message Translation</h5>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="flow flow5">
            <div class="circle">
              <img src="./../../../../../assets/images/calendar-tasks.svg" class="d-block img-fluid" alt="calendar-tasks" />
            </div>
            <h5>Calendar and Tasks</h5>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="flow flow6">
            <div class="circle">
              <img src="./../../../../../assets/images/notes.svg" class="d-block img-fluid" alt="notes" />
            </div>
            <h5>Notes</h5>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="flow flow7">
            <div class="circle">
              <img src="./../../../../../assets/images/send-later.svg" class="d-block img-fluid" alt="send-later" />
            </div>
            <h5>Send Later</h5>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="flow flow8">
            <div class="circle">
              <img src="./../../../../../assets/images/quick-test.svg" class="d-block img-fluid" alt="quick-test" />
            </div>
            <h5>Quick Text</h5>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="home-comp-six">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <h3>Backed by enterprise-grade security</h3>
          <p>O-Mail works around the clock to help protect your privacy
            and keep your inbox free of clutter.</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 col-lg-3">
          <div class="enterprise-grade-box">
            <img src="./../../../../../assets/images/protection-delivered.png" class="d-block mx-auto img-fluid" alt="protection-delivered" />
            <h5>Protection Delivered</h5>
            <p>Protection delivered by the
              same tools Onpassive uses for business customers.</p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="enterprise-grade-box">
            <img src="./../../../../../assets/images/protection-delivered.png" class="d-block mx-auto img-fluid" alt="protection-delivered" />
            <h5>Data Encryption</h5>
            <p>Protection delivered by the
              same tools Onpassive uses for business customers.</p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="enterprise-grade-box">
            <img src="./../../../../../assets/images/deactivation.png" class="d-block mx-auto img-fluid" alt="deactivation" />
            <h5>Automatic Deactivation</h5>
            <p>Protection delivered by the
              same tools Onpassive uses for business customers.</p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="enterprise-grade-box">
            <img src="./../../../../../assets/images/ransomeware.png" class="d-block mx-auto img-fluid" alt="ransomeware" />
            <h5>Ransomware</h5>
            <p>Protection delivered by the
              same tools Onpassive uses for business customers.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
